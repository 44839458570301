.filter_btn {
  display: flex !important;
  align-items: center;
  // justify-content: space-between;
  padding: 2px 2px 2px 16px !important;
  border-radius: 24px !important;
  background-color: #fff !important;
  border: 1px solid #e4e7ec !important;
  span {
    margin-right: 12px;
    font-family: "Poppins";
    color: #02988e;
    font-size: 14px;
    font-weight: 500;
  }
  figure {
    margin: 0;
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e1f4f4;
    border-radius: 50%;
  }
}

.filter_btn_active {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    top: 11px;
    right: 13px;
    width: 6px;
    height: 6px;
    background-color: #f67280;
    border-radius: 50px;
  }
}
