.dayViewWrapper table thead {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 50;
}

.dayViewWrapper table thead tr th:first-child {
  position: sticky;
  top: 0;
  left: 0;
}

.dayViewWrapper table tbody tr:not(:last-child) {
  min-width: 500px;
  border-bottom: 1px solid #e9e9e9;
}

.dayViewWrapper table thead tr th:first-child::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #e9e9e9;
  right: 0;
  top: 0;
  z-index: 6;
  left: 0;
}

.dayViewWrapper table tbody tr td:first-child {
  min-height: 156px;
  height: 100%;
}

.dayViewWrapper table tbody tr td:first-child::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #e9e9e9;
  right: 0;
  top: 0;
}

.week-view-wraper table tbody tr td:first-child::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #e9e9e9;
  right: 0;
  top: 0;
}

.day-view-container {
  width: 100%;
  height: 100%;
  // max-height: 714px;
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  margin-bottom: 24px;
  position: relative;
}

.dayViewWrapper {
  position: relative;
  overflow: auto;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  border: 1px solid #e9e9e9;
  border-radius: 8px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.dayViewWrapper .sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  min-width: 234px;
  height: 100%;
  z-index: 22;
}

.dayViewWrapper .table-row {
  height: 156px;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;
}

.dayViewWrapper .branch-header {
  padding: 0px;
  // width: 237px;
  height: 58px;

  /* Colors/Grey/Grey 100 */

  background: #edf2f7;

  .branch-name-heading {
    // position: relative;
    display: flex;
    align-items: center;
    padding: 16px 12px;
    // overflow: hidden;
    min-width: 234px;
    height: 58px;
    max-height: 58px;

    background: #eff1f7;

    .badge {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 8px;
      width: 32px;
      height: 22px;
      padding: 2px 8px;
      background: #e1f4f4;
      border-radius: 16px;
      color: #09988d;
    }
  }
}

.dayViewWrapper .week-days-header {
  padding: 0px;
  width: 100%;
  height: 58px;

  /* Colors/Grey/Grey 100 */

  background: #edf2f7;
  //   position: absolute;
}

.week-day-heading-cell-day-view {
  text-align: center;
  padding: 8px 0px;
  width: 100%;
  min-width: 166px;
  background: #eff1f7;
  border-left: 1px solid #e9e9e9;
  vertical-align: middle !important;
}

.dayViewWrapper .first-col {
  width: 235px;
  max-width: 235px;
  height: 100%;
  left: 0px;
}

.branch-name-cell {
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  cursor: pointer;
  background: #ffffff;
}

.week-day-detail-cell-row {
  padding: 0px;
  width: 100%;
  // height: 132px;

  /* Colors/Grey/Grey 100 */

  background: #edf2f7;
  //   position: absolute;
  display: flex;
  align-items: center;
}

.schedular-slot-container {
  width: 100%;
  max-width: 166px;
  min-width: 166px;
  min-height: 100%;

  /* Black/Black 00 */

  background: #ffffff;
  /* Colors/Black/Black 100 */
  padding-top: 5px;
  border-right: 1px solid #e9e9e9;
}

.table-bodered {
  &.dayview-table {
    border: 1px solid #e9e9e9;
  }
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: solid !important;
  border-color: #e4e7ec !important;
}

.day-view-wraper {
}
