.img-wrap {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid rgba(16, 24, 40, 0.2);
  img {
    border-radius: 50px;
    width: 100%;
    height: 100%;
  }
}

.select-wrap {
  position: relative;
  // .img-wrap {
  //   position: absolute;
  //   left: 12px;
  //   width: 32px;
  //   height: 32px;
  //   top: 50%;
  //   transform: translateY(-50%);
  // }
  .text-input-field {
    height: 48px !important;
  }
  .ant-select {
    .ant-select-selector {
      // padding-left: 45px !important;

      .ant-select-selection-placeholder {
        color: #101828 !important;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
.header-section {
  padding: 16px;
  border-bottom: 1px solid #e4e7ec;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .staff-tag {
    padding: 8px 12px;
    border-radius: 24px;
    border: 1px solid #e4e7ec;
    background: #f2f4f7;

    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins";
    span {
      color: #667085;
    }
  }
  .staff-info {
    margin-right: 24px;
    .staff-name {
      P {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
      span {
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-weight: 500;
        color: #667085;
      }
    }
  }

  .rgt-btns {
    margin-left: auto;
  }
}

.body-section {
  padding: 0 16px 16px 16px;
  .bordered-btn {
    border: 1px dashed #02988e;
    width: 100%;
    justify-content: center;
    font-size: 14px !important;
  }
  .bottom-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;

    .custom_btn {
      &:first-child {
        margin-right: 12px;
      }
      font-size: 14px !important;

      &.outlined {
        border-color: #475467;
        color: #475467 !important;
        &:hover {
          color: #fff !important;
        }
      }
    }
  }

  .perform-question-card {
    padding: 16px;
    background-color: #f9fafb;
    border-radius: 12px;
    border: 1px solid #e4e7ec;
    &.add-question {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
    .bgGreen {
      background-color: #28b4ac;
    }
    span {
      border-radius: 50px;

      width: 21px;
      height: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      font-family: "Poppins", sans-serif;
    }
    h5 {
      font-size: 16px;
      font-weight: 500;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: #101828;
      line-height: normal;
      font-family: "Poppins", sans-serif;
    }
    .input-wrap {
      label {
        margin-bottom: 8px;
        color: #344054;
      }
      textarea {
        border-radius: 16px;
        border: 1px solid #e4e7ec;
        background: #fff;
      }
    }
  }
}

.staffReview-inner {
  border: 1px solid #e4e7ec;
  border-radius: 12px;
  background-color: #fff;
}
.search-section {
  margin-bottom: 16px;
  // position: absolute;
  display: flex;
  justify-content: flex-end;

  align-items: center;
  width: 100%;
  &.inside-card {
    padding: 12px;
    margin-bottom: 12px;
    justify-content: flex-start;

    .input-wrap {
      &:first-child {
        margin-right: 24px;
      }
      label {
        margin-bottom: 6px;
      }
      .text-input-field {
        min-width: 200px;
        width: 100%;
        border-radius: 50px;
      }
    }

    .datepicker-wrap {
      position: relative;
      .date-picker-container {
        padding: 4px 12px;
        border-radius: 50px !important;
        max-height: none;
      }
      .ant-picker {
        min-width: 308px !important;
        // width: 100%;
        height: auto;

        .ant-picker-input {
          input {
            text-align: start !important;
          }
        }
      }
      .dropdown-abs {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
      }
    }
  }

  .ant-input-affix-wrapper {
    max-width: 260px;
    height: 48px;
    border-radius: 50px !important;
    input {
      padding-left: 4px !important;
    }
  }

  .custom_btn {
    background-color: #02988e !important;
  }

  // top: -112px;
}
.add-q-modal {
  .input-wrap {
    label {
      margin-bottom: 8px;
    }
    textarea {
      border-radius: 16px;
    }
    &:first-child {
      margin-bottom: 24px;
    }
  }
}
.confirmModal {
  .modal-dialog {
    .modal-content {
      .main_modal_body {
        .performance-review-modal {
          .confirm_text {
            font-size: 14px !important;
            font-weight: 400 !important;
            color: #101828;
            span {
              font-weight: 600 !important;
            }
          }
        }
      }
    }
  }
}
