.segment-row {
  margin-bottom: 42px;
}
.segment-footer {
  padding: 24px 24px 0 24px;
  border-top: 1px solid #e4e7ec;
  button {
    max-width: 145px;
  }
}
.segment-each {
  border-radius: 16px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  padding: 16px;
  transition: all 0.2s ease-in-out;

  &.selected,
  &:hover {
    border: 1px solid #09988d;
    background-color: #e1f4f4;

    figure {
      background-color: #09988d;
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
  figure {
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f5f9;
    border-radius: 8px;
    margin-bottom: 16px;
  }

  .segement-title {
    font-size: 14px;

    font-weight: 500;
  }
}
.segmentaionModal {
  .modal-dialog {
    max-width: 1284px !important;
    .modal-content {
      height: calc(100vh - 70px);
      border: none;
    }
  }
  .slider_wrapper {
    .slick-dots-bottom {
      padding-bottom: 0px !important;
    }
  }
}
