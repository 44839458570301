.dashboard-page {
  width: 1024;
  background-color: rgba(218, 218, 218, 0.2);
  // margin-bottom: 49px;
  // height: 100vh;
  // overflow: hidden;

  &:has(.locum-table) {
    height: calc(100vh - 60px);
  }

  .content-container-dashboard {
    // height: 100%;
  }
}

.date-filter-field {
  margin-bottom: 20px;

  .ant-picker-suffix {
    display: none;
  }
}

.locum-card-space-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .ant-space-item {
    &:first-child {
      width: 50%;
    }
    height: 100%;
  }

  .separated-item {
    h4 {
      margin-bottom: 0;
    }
    p {
      color: #667085;
    }
  }
}
.range-picker-wrap {
  margin-top: 24px;
  &.filter-modal {
    margin-top: 0;
  }
  .ant-form-item-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .ant-form-item-control {
    width: 100%;
  }
  .ant-form-item-label {
    label {
      font-size: 12px;
      font-weight: 500;
      color: #000;
      font-family: 'Poppins';
    }
  }
  .ant-picker-range {
    width: 100%;
    // padding: 14px 14px !important;

    border: none;
    box-shadow: none;
    padding: 0 !important;

    .ant-picker-input {
      border-radius: 8px;
      padding: 12px 14px;
      border: 1px #d6d9e1 solid;
      position: relative;

      &::after {
        position: absolute;
        content: '';
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        height: 24px;
        width: 24px;
        background-image: url('../../../assets/icons/calendar.svg');
        background-repeat: no-repeat;
      }
      &:nth-child(3) {
        &::before {
          position: absolute;
          content: 'End Date';
          left: 0;
          top: -22px;
          height: 24px;
          width: 60px;
          text-wrap: nowrap;
          font-size: 12px;
          font-weight: 500;
          color: #000;
          font-family: 'Poppins';
          line-height: 18px;
        }
      }
    }
    .ant-picker-active-bar {
      width: 196px !important;
    }
  }
}
