.locum-card {
  border-radius: 12px;
  border: 1px solid #e4e7ec;
  background: #fff;
  padding: 12px;
  display: flex;
  align-items: flex-start;

  .icon_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    margin-right: 8px;
  }
  .tab-header {
    span {
      font-size: 12px;
      font-weight: 400;
      color: #555555;
    }
    h5 {
      font-size: 18px;
    }
  }
}

.locum-table {
  max-height: calc(100vh - 200px);
  // margin-bottom: 80px;
  overflow: auto;
  position: relative;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .table-wrapper {
    // max-height: calc(100vh - 400px);
    overflow: auto;
  }
  .footer-wrapper {
    background-color: #fff;
    position: sticky;
    bottom: 0;
  }
}
.locum-table::-webkit-scrollbar {
  width: 0; /* Set to 0 to hide the scrollbar */
}

/* Firefox */
.locum-table::-moz-scrollbar {
  width: 0;
}

/* Other scrollbar styles (optional) */
.locum-table::-webkit-scrollbar-thumb {
  background-color: #888;
}

.locum-table::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.footer_left {
  form {
    label {
      font-family: "Inter" !important;
      font-size: 14px;
      font-weight: 400;
      color: #000;
    }
    border-right: 1px solid #e9e9e9;
    padding-right: 13px;
  }
  .items_toshow {
    padding-left: 24px;
    font-family: Inter;
    font-size: 14px;
    color: #9d9d9d;
    font-weight: 400;
  }
}
.footer-wrapper {
  padding: 0 16px;
}
.footer-right {
  form {
    label {
      font-family: "Inter" !important;
      font-size: 14px;
      font-weight: 400;
      color: #9d9d9d;
    }
    // border-right: 1px solid #E9E9E9;
    padding-right: 32px;
  }
  .arrow-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      border: none;
      background: none;
      box-shadow: none;
      height: auto;
      width: auto;
      padding: 12px;
      border-radius: 0 !important;
      &:first-child {
        border-right: 1px solid #e9e9e9;
        border-left: 1px solid #e9e9e9;
      }
    }
  }
}
.locum-job-detail-modal {
  .pharma-img-wrap {
    width: 40px;
    height: 40px;
  }
  .jobinfo-details-row {
    margin-top: 24px;
    margin-bottom: 40px;
  }
  .jobdetailsinfo-cols {
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
    }
    p {
      margin-right: 8px;
      font-size: 14px;
      font-weight: 500;
      font-family: "poppins", sans-serif;
      color: #555555;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      font-family: "poppins", sans-serif;
      color: #000;
    }
  }
}
.tag-wrap {
  .ant-tag {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    border: none;
    border-radius: 50px;
    padding: 6px 12px 6px 10px;
    width: auto;
    // max-width: 153px;
    svg {
      margin-right: 6px;
      width: 14px;
      height: 14px;
    }
    span {
      font-family: "Poppins", sans-serif;
      font-size: 12px;
      font-weight: 500;
      overflow: visible !important;
    }

    &.unfilled {
      border: 1px dashed #555555;
      background-color: #fff;
    }
    &.pending {
      background: #feefda;
      span {
        color: #db810b;
      }
    }
    &.assigned,
    &.payment-pending {
      background: #f0f9ff;
      span {
        color: #026aa2;
      }
    }
    &.cancelled {
      background: rgba(211, 47, 47, 0.08);
      span {
        color: #d32f2f;
      }
    }
    &.completed {
      background: #ecfdf3;
      span {
        color: #027948;
      }
    }
  }
}
.rating-star {
  li {
    .anticon-star {
      svg {
        height: 30px !important;
        width: 30px !important;
      }
    }
  }
}
.cover-expence-info {
  margin-bottom: 40px;
  .rules {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    p {
      line-height: normal;
      color: #555555;
      font-size: 14px;
      font-weight: 500;
      width: 40%;
      font-family: "poppins", sans-serif;
    }
    .rule-space {
      width: 50%;
    }
    span {
      i {
        margin-right: 6px;
      }
      color: #000;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .rule-edit {
    background-color: #f6f6f6;
    border-radius: 12px;
    padding: 12px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    .rules {
      margin-bottom: 10px;
    }
    .ant-select {
      background-color: #fff;
    }
  }
}
.description-info {
  margin-bottom: 40px;
  .heading {
    font-weight: 500;
  }
  .desc-box {
    margin-top: 4px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #e2e2e2;

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.cancel-reason {
  .jobdetailsinfo-cols {
    margin-bottom: 16px;
  }
}
.assigned-locum-crd {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  padding: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  .button-wrap {
    margin-left: auto;
    display: flex;

    button {
      &:first-child {
        margin-right: 8px;
      }
      background: #02988e !important;
      &.outlined {
        background-color: #fff !important;
        border-color: #d9d9d9;
        span {
          color: #434343;
        }
      }
      span {
        color: #fff;
      }
    }
  }
  .assinged-locum-cols {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      line-height: normal;
      font-size: 12px;
      font-weight: 400;
      font-family: "poppins", sans-serif;
      color: #555555;
    }
    span {
      color: #101828;
    }
  }
  .locum-details {
    &.pending {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .locum-name {
      font-size: 16px;
      font-weight: 500;
      color: #191d23;
      line-height: normal;
    }

    .email,
    .phone {
      color: #7b7b7b;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }
  }
  .card-left {
    &.pending-card {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
    }
  }
  .card-right {
    margin-left: auto;
    .rating {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.shift_timing_wrapper {
  list-style-type: none !important;
  padding: 0 28px;
  margin-bottom: 40px;
  li {
    position: relative;
    ::before {
      content: "";
      position: absolute;
      height: 16px;
      width: 16px;
      left: -25px;
      top: 0;

      border-radius: 50px;
      background-color: #fff;
      border: 1px solid #d9d9d9;
      z-index: 2;
    }
    &:not(:last-child) {
      padding-bottom: 18px;
      ::after {
        content: "";
        position: absolute;
        top: 0;
        left: -18px;
        height: 100%;
        width: 0px;
        border-left: 2px dashed #d9d9d9;
        z-index: 1;
      }
    }
  }
  .shift_times {
    .shift_no {
      font-size: 14px;
      font-weight: 400;
      color: #555555;
      font-family: "Poppins";
      margin-bottom: 4px;
    }
    .duration {
      p {
        font-size: 20px;
        font-weight: 600;
        span {
          color: #555555;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    .break_time {
      margin-top: 20px;
    }
  }
}

.rating-card {
  padding: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  .text-btn {
    padding: 0;
    border: none;
    box-shadow: none;
    margin-top: 10px;
    span {
      color: #02988e;
      font-size: 12px;
      font-weight: 400;
    }

    &:hover {
      box-shadow: none;
    }
  }
}

.confirmModal {
  .modal-dialog {
    &:has(.confirm-locum) {
      max-width: 447px !important;
      .modal-content {
        border: none;
      }
      .modal_header {
        font-size: 16px;
      }
      .modal-body {
        padding: 12px 24px !important;
      }
      .main_modal_body {
        border: none;
        padding: 0 0px 12px 0px;
        margin: 0;
        span {
          font-size: 16px;
          font-weight: 400;
        }
      }
      .modal_bottom {
        button {
          padding: 9px 16px;
          border-radius: 50px;
          min-width: auto;
          &.cancel_btn {
            border: 1px solid #475467;
            span {
              color: #475467 !important;
            }
          }
          &.save {
            background-color: #02988e;
            &:hover {
              border: 1px solid #475467;
            }
          }
        }
      }
    }

    &:has(.confirm-delete) {
      max-width: 589px !important;
      .modal-content {
        border: none;
      }
      .modal_header {
        font-size: 16px;
      }
      .modal-body {
        padding: 12px 24px !important;
      }
      .main_modal_body {
        // border: none;
        padding: 24px;
        // margin: 0;
        margin: 0 -24px;
        .confirm-delete {
          font-size: 16px;
          font-weight: 600;
        }
      }
      .modal_bottom {
        button {
          padding: 9px 16px;
          border-radius: 50px;
          min-width: auto;
          &.cancel_btn {
            border: 1px solid #475467;
            span {
              color: #475467 !important;
            }
          }
          &.save {
            background-color: #02988e;
            &:hover {
              border: 1px solid #475467;
            }
          }
        }
      }
    }
  }
}
