.breadcrumb-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 14.5px 24px;
  background-color: #fff;
  border-bottom: 1px solid #e9e9e9;
  .ant-breadcrumb {
    li {
      color: #7b7b7b;
      &:last-child {
        color: #000;
      }
    }
    .ant-breadcrumb-link {
      font-size: 14px;
      font-weight: 400;
      font-family: "Poppins";
    }
  }
  .last_updated {
    display: flex;
    align-items: center;
    i {
      display: flex;
      align-items: center;
      margin-right: 8px;
    }
    p {
      color: #7b7b7b;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
