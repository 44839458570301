.ant-picker-dropdown {
  font-family: 'Poppins' !important;
  .ant-picker-panel-layout {
    padding: 12px;
    .ant-picker-header {
      border-bottom: none;
    }

    .ant-picker-panels {
      .ant-picker-panel {
        border: none;
        &:first-child {
          .ant-picker-body {
            padding-right: 0 !important;
            margin-right: -5px;
          }
        }
        &:last-child {
          .ant-picker-body {
            padding-left: 0 !important;
            margin-left: -5px;
          }
        }
      }
    }
  }
  .ant-picker-footer {
    button {
      &:first-child {
        margin-right: 8px;
      }
      span {
        display: block;
      }
      &.save {
        background-color: #51c2bc;
        border: 1px solid #51c2bc;
        span {
          color: #fff;
        }
        &:hover {
          background-color: #fff;
          span {
            color: #51c2bc;
          }
        }
      }
    }
  }
  .modal_title {
    font-size: 20px;
    font-weight: 500;
  }
  .ant-picker-range-wrapper {
    .ant-picker-body {
      table {
        thead {
          th {
            color: #7b7b7b !important;
            font-size: 12px !important;
            font-weight: 500 !important;
          }
        }
        tbody {
          tr {
            td {
              &:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
                display: none;
              }

              &:not(.ant-picker-cell-in-view) {
                pointer-events: none;
              }

              &.ant-picker-cell-in-view {
                color: #555555;
                font-size: 12px !important;
                font-weight: 400 !important;
              }
              &.ant-picker-cell-today {
                .ant-picker-cell-inner {
                  &::before {
                    border: none !important;
                  }
                }
                color: #0ba69b;
              }
              &.ant-picker-cell-selected {
                .ant-picker-cell-inner {
                  background: #09988d;
                  border: 2px #cae6e3 solid !important;
                  box-sizing: content-box;
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal.fade.modal-animation-right .modal-dialog {
  transform: translate(47%, 0px);
}

.modal.show.modal-animation-right .modal-dialog {
  transform: translate(0%, 0px);
}

.create-client-modal {
  height: 100%;
  .modal-dialog .modal-content {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    max-width: 500px;
    background: #ffffff;
    border-radius: 0;
    .btn-close {
      opacity: 1;
      height: 20px;
      width: 20px;
    }
  }

  .ant-form-item-control-input {
    .ant-form-item-control-input-content {
      button {
        &.ant-switch {
          background-color: #e2e8f0;
          &.ant-switch-checked {
            background-color: #51c2bc;
          }
        }
        // .ant-switch-inner{
        //   background-color: #E2E8F0 !important;

        // }
      }
    }
  }
  // padding: 24px 24px 10px 24px;
  .modal-body {
    height: calc(100vh - 139px);
    padding: 8px 24px 0px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
    .ant-select-selection-placeholder {
      color: #7b7b7b !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      font-family: 'Poppins';
    }
    h3 {
      &.heading4 {
        margin-bottom: 0 !important;
      }

      .add-shift-modal-content {
        gap: 16px;
        width: 100%;
        margin-top: 0px !important;

        .ant-picker-range {
          border: none !important;
          padding: 0 !important;
          box-shadow: none !important;
        }
      }
    }

    .shift_job_radio_group {
      .ant-radio-inner {
        width: 20px;
        height: 20px;
      }

      .ant-radio-group {
        &.shift_radio_group {
          .ant-radio-wrapper {
            span {
              font-family: 'Poppins' !important;
            }
          }
        }
      }
      .ant-radio-checked {
        .ant-radio-inner {
          background-color: #e1f4f4 !important;
          border: 1px solid #09988d !important;
          &:after {
            background: #09988d !important;

            width: 20px;
            height: 20px;

            margin-block-start: -10px;
            margin-inline-start: -10px;
          }
        }
      }
    }
  }
  .ant-checkbox-wrapper-checked {
    .ant-checkbox-inner {
      background-color: #e1f4f4;
      border-color: #09988d;
      &::after {
        border-color: #09988d;
      }
    }
  }

  .modal-header {
    border: none;
    padding: 24px;
    width: 100%;
    // margin-bottom: 0;
    .btn-close {
      height: 0 !important;
      width: 0 !important;
    }
  }

  .modal-footer {
    border: none;
    padding: 0px !important;
    margin: 24px;
  }

  .btn-style {
    &.cancel {
      background-color: #fff;
      border: 1px solid #51c2bc;
      span {
        color: #51c2bc;
      }
      &:hover {
        background-color: #51c2bc;
        span {
          color: #fff;
        }
      }
    }
  }

  .add-modal-date-container {
    width: 100%;
    margin-bottom: 6px;

    .heading {
      width: 100%;
      margin-bottom: 10px;
    }

    .date-box {
      padding: 10px;
      width: 160px;
      height: 40px;
      /* Black/Black 0 */
      background: #ffffff;
      /* Black/Black 200 */

      border: 1px solid #d9d9d9;
      border-radius: 8px;
    }
  }

  .full-width-selector {
    padding: 10px 16px;
    width: 100%;
    height: 40px;

    /* Black/Black 0 */

    background: #ffffff;
    /* Black/Black 200 */

    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }

  .add-shift-footer {
    width: 100%;
    gap: 10px;

    .footer-btn {
      padding: 10px 16px;
      width: 100%;
      height: 44px;
      cursor: pointer;
      border-radius: 10px !important;
    }

    .primary-btn {
      background-color: #9d9d9d !important;
      color: #f5f5f5 !important;
    }

    .secondary-btn {
      background-color: #f5f5f5 !important;
      color: #9d9d9d !important;
    }
  }

  .modal.fade .modal-dialog {
    transform: none !important;
    transition: none !important;
  }
  .fixWidthContent {
    .modal-dialog .modal-content {
      max-width: 817px !important;
    }
  }
}

.timesheet_modal {
  .modal-content {
  }
  --bs-modal-width: 80% !important;
  @media (max-width: 1280px) {
    --bs-modal-width: 90% !important;
  }
  .modal-header {
    border-bottom: 1px solid #f2f4f7;
  }
  .modal-footer {
    padding: 24px 20px;
    border-top: 1px solid #f2f4f7;
  }

  .modal_header {
    margin-bottom: 0;
    font-weight: 500;
    color: #101828;
  }
  .employee_Name_td {
    .emp_img {
      height: 40px;
      width: 40px;
      .ant-avatar {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 16px;
      }
    }
    .title {
      h6 {
        margin-bottom: 6px;
        a {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .modal_header_right {
    display: flex;
    .edit_button {
      height: 48px;
      width: 48px;
      border: 1px solid #e4e7ec;
      border-radius: 50px !important;
      padding: 7px 0 !important;
      &:hover {
        border: 1px solid #09988d;
      }
      // display: flex;justify-content: center;align-items: center;
    }
    button {
      height: 48px;
      padding: 7px 13px !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  .modal_filter_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 16px;

    .filter_right {
      margin-left: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .workstatus {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:not(:last-child) {
          margin-right: 16px;
        }
        font-family: 'Poppins';
        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
        p {
          margin-bottom: 0;
          margin-right: 8px;
          line-height: 1;

          font-size: 14px;
          color: #7b7b7b;
          font-weight: 500;
        }
        span {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  .modal_bottom {
    // padding: 12px 0px;

    display: flex;
    justify-content: flex-end;
    .cancel_btn {
      height: auto;
      padding: 9px 16px;
      max-width: 145px;
      width: 100%;
      border-radius: 50px;
      margin-right: 12px;
      border: 1px solid #475467;
      color: #475467 !important;
      display: flex;
      justify-content: center;
    }
    .save {
      width: auto !important;
      background-color: #02988e !important;

      &:hover {
        background-color: #027a48 !important ;
      }
    }
  }

  .timesheet_modal_table_wrapper {
    .ant-table-body {
      overflow-x: auto !important;
      overflow: auto !important;
    }
    .ant-table-title {
      padding: 12px 24px !important;
      background-color: #f3f8fa !important;
      border-radius: 12px 12px 0 0;
    }
    .ant-table-header {
      border-radius: 0;
    }
    .ant-table-container {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
    .ant-table-cell-scrollbar {
      background: #f5f8f9;
      box-shadow: 0 1px 0 1px #f5f8f9;
    }
    table {
      font-family: 'Poppins';
      thead {
        th {
          background: #f5f8f9;
          font-size: 14px;
          font-weight: 500;
        }
      }
      tbody {
        td {
          font-size: 14px;
          font-weight: 400;
          font-family: 'Inter';
          color: #344054;
          padding: 14.6px 12px;
          vertical-align: middle;
          .error-icon {
            color: red;
            font-size: 14px;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
          }
          &:has(.has-error) {
            border-color: red !important;
          }
          &:has(.modal_table_input) {
            padding: 0;
            // border-bottom: 1px solid #000 !important;
            // border-inline-end: 1px solid #000 !important;
          }
          &:has(.edit_td) {
            border: 1px solid transparent;
            background-color: #fcfeff;
            // &:hover {
            //   border: 1px solid #000;
            //   border-bottom: 1px solid #000 !important;
            //   border-inline-end: 1px solid #000 !important;
            // }
          }
          &:has(.presence_dropdown) {
            border: 1px solid transparent;
            &:hover {
              border: 1px solid transparent !important;
              border-bottom: 1px solid transparent !important;
              border-inline-end: 1px solid transparent !important;
            }
          }
        }
      }
    }
    .week_button {
      font-family: 'DM Sans';
      font-size: 14px !important;
      font-weight: 400;
      padding: 4px 12.1px !important;
      height: 32px;
      border: 1px solid transparent;
      border: none;
      &.custom_btn {
        font-weight: 700 !important;
      }
    }
    .date_render {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        margin-bottom: 0;
        color: #555555;
        font-size: 14px;
        font-weight: 500;
        margin-right: 4px;
      }
      b {
        font-size: 14px;
        font-weight: 500;
        color: #000;
      }
    }
  }
  .pharmacy_details_dropdown {
    display: flex;
    figure {
      height: 24px;
      width: 24px;
      border-radius: 50px;
      margin: 0;
      margin-right: 8px;
      margin-bottom: 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    p {
      color: #344054;
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
      margin-right: 6px;
    }
  }
}
.modal-header-right {
  display: flex;
  align-items: center;

  > div {
    margin-right: 6px;
    button + button {
      border-left: 1px solid #d9d9d9;
    }
    button {
      padding: 0 6px;
      height: auto;
      &:not(:last-child) {
        margin-right: 4px;
      }
      &:last-child {
        padding-left: 12px;

        border-radius: 0;
      }
      &:hover {
        background: none !important ;
      }
    }
  }
}
.ant-dropdown {
  z-index: 1099 !important;
}
.ant-dropdown-menu-submenu {
  z-index: 1199 !important;
}
.modal_table_input {
  border: none;
  overflow-y: hidden;
  width: 100%;
  box-shadow: none;
  background-color: transparent !important;
  &:focus {
    border: none;
    box-shadow: none;
  }
  &:focus-visible {
    outline: none !important;
  }

  .ant-input {
    border: none;
    background-color: transparent !important;
    &:focus,
    &:active,
    &:hover {
      border: none;
      box-shadow: none;
      width: 100%;
      padding: 0;
    }
  }
}
.edit_button {
  padding: 0 !important;
  margin: 0;
  // width: auto;
  height: auto;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.time {
  margin-bottom: 0;
  color: #344054;
  margin-right: 6px;
  line-height: 1;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
}
.ant-dropdown-menu {
  list-style-type: none !important;
  width: auto;
}
.ant-dropdown-menu-submenu-title {
  padding-inline-end: 0px !important;
}

.ant-dropdown-menu-submenu,
.ant-dropdown-menu-item {
  .ant-dropdown-menu-submenu-expand-icon {
    display: none;
  }
  span {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
  }
  .ant_sub_menu {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
  }
}
.ant-dropdown-menu-item-active {
  background-color: #fff !important;
}
.ant-dropdown-menu-sub {
  .ant-dropdown-menu-item {
    padding: 5px 4px !important;
  }
}
.presence_dropdown {
  figure {
    margin: 0;
  }
  ul {
    // list-style-type: none !important;

    li {
    }
  }
}
.dropdown_input {
  margin-bottom:6px;
  .ant-form-item{
    margin-bottom: 0;
  }

  .ant-input-prefix {
    img {
      width: 16px;
      height: 16px;
    }
  }
  .ant-input-affix-wrapper {
    border-radius: 8px !important;
    border: 1px solid #d9d9d9;
    background: #fff;
    height: 34px;

    ::placeholder {
      font-size: 14px;
      font-weight: 400;
      color: #7b7b7b !important;
    }
  }
}
.pharma_options_wrapper {
  padding-left: 0;
  margin-bottom: 0;
  ul {
    margin-inline: 0 !important;
  }
  li {
    padding: 8px 0;
    &:hover {
      background-color: #f9f9f9;
    }
  }
  .pharma_info {
    display: flex;
    align-items: center;
    i {
      margin-right: 8px;
      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
    p {
      color: #000;
      font-size: 14px;
      font-family: 'Poppins';
      font-weight: 400;
    }
  }
}
.ant-popover {
  z-index: 1199;
  padding-left: 20px;
  .ant-popover-inner {
    min-width: 280px;
    &:has(.shift_popup) {
      min-width: 500px;
    }
  }
}

// .ant-dropdown-menu-submenu-hidden{
//   display: block;
// }
// .ant-dropdown-hidden{
//   display: block;
// }

.modal_popover {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none !important;
  li {
    button {
      padding: 0;
    }
    cursor: pointer;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    padding: 8px;
    &:hover {
      background-color: #fafafa;
    }
  }
}

.select_field {
  min-width: 115px !important;
}
.date_selector {
  min-width: 253px !important;
  button {
    padding-left: 0;
    padding-right: 0;
    display: flex;
  }
}
.main_modal_body {
  .selected_date {
    p {
      font-size: 14px !important;
      font-weight: 500;
    }
  }
}

.custom_table_header {
  display: flex;
}

.confirm_modal {
  .ant-modal-confirm-btns {
    display: flex;
    justify-content: flex-end;
  }
}

.no-user-select {
  user-select: none;
}
