@import "../../assets/scss/typography";
.privacyPolicyPage {
  width: 100%;
  height: auto;
  background-image: url("../../assets/img/errorBg.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 50% 25%;
  // overflow: hidden;
  .privacy-content {
    width: 75%;
    height: auto;
    margin:120px 60px 60px 60px;
   
    // background-image: url("../../assets/img/errorBg.png");
    // background-size: auto;
    // background-repeat: no-repeat;
    // background-position: 50% 25%;
  }
  li {
    @extend .p2;
    color: #7b7b7b;
    margin-bottom: 15px;
  }
}
