.branch-detail-header {
  height: 58px;
  /* Colors/Grey/Grey 100 */
  background: #edf2f7;
  /* Colors/Black/Black 100 */
  // border: 1px solid #e9e9e9;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 40;
  padding-left: 16px;
}

.time-slot-row {
  height: 84px;
  width: 100%;
  border-top: 1px solid #e9e9e9;
}

.time-slot-header {
  width: 118px;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  background: #ffffff;
  /* Colors/Black/Black 100 */

  border-right: 1px solid #e9e9e9;
}