//Colors
// theme colors
$locum-red: #d82700 !default;
$locum-blue: #2568ea !default;
$locum-green: #8ac575 !default;
$locum-grey: #4a4a4a !default;
$locum-orange: #f7bd4e !default;
$locum-orange-new: #ffcc00 !default;

// main colors
$white: #fff !default;
$black: #000 !default;
$lighten-black: #444 !default;
$grey: #888 !default;
$lighten-grey: #c4c4c4 !default;
$red: #9f3a38 !default;

$error-background-color: #fff6f6 !default;
$error-border-color: #e0b4b4 !default;

$closed-branch-color: #c3c3c3 !default;

$is-draft-color: #62c0c0 !default;
$job-posted-color: #f6bd4e !default;
$input-border-color: #dee2e6 !default;

$primaryBgColor: #f9f9f9;

$maxWidth: 1320px;



//======= update color variables ================//

// // Kindly press Ctrl+A and then press Ctrl+/ to use the code


//White color
$white: #FFFFFF;
$black: #000000;

// Black colors

$black-900: #000000;
$black-800: #262626;
$black-700: #434343;
$black-600: #555555;
$black-500: #7b7b7b;
$black-400: #9d9d9d;
$black-300: #c4c4c4;
$black-200: #d9d9d9;
$black-100: #e9e9e9;
$black-50: #f5f5f5;


//Grey colors

$grey-900: #171923;
$grey-800: #1A202C;
$grey-700: #2E3747;
$grey-600: #4B5567;
$grey-500: #728095;
$grey-400: #A1AEBF;
$grey-300: #CCD5DF;
$grey-200: #E2E8F0;
$grey-100: #EDF2F7;
$grey-50: #CCD5DF;

//Prmary colors

$primary-900: #065a4f;
$primary-800: #07776c;
$primary-700: #07877c;
$primary-600: #09988d;
$primary-500: #0ba69b;
$primary-400: #2bb4ac;
$primary-300: #51c2bc;
$primary-200: #84d4d0;
$primary-100: #b5e4e2;
$primary-50: #e1f4f4;


//Secondary colors

$secondary-900: #000939;
$secondary-800: #041a4f;
$secondary-700: #0d235b;
$secondary-600: #162b65;
$secondary-500: #1c316c;
$secondary-400: #3e4c7f;
$secondary-300: #5e6993;
$secondary-200: #8992b1;
$secondary-100: #b7bdd1;
$secondary-50: #e2e4ec;


//success colors

$success-900: #1b5e1f;
$success-800: #2e7d31;
$success-700: #388e3b;
$success-600: #43a046;
$success-500: #4caf4f;
$success-400: #66bb69;
$success-300: #81c784;
$success-200: #a5d6a7;
$success-100: #c8e6c9;
$success-50: #e8f5e9;


//  Errors colors

$error-900: #b71b1c;
$error-800: #c62828;
$error-700: #d32f2f;
$error-600: #e53935;
$error-500: #f44336;
$error-400: #ef5350;
$error-300: #e57373;
$error-200: #ef9a9a;
$error-100: #ffcdd2;
$error-50: #ffebee;


//information colors

$info-900: #1045a1;
$info-800: #1764c0;
$info-700: #1a75d2;
$info-600: #1f87e5;
$info-500: #2194f3;
$info-400: #42a4f5;
$info-300: #63b4f6;
$info-200: #90c9f9;
$info-100: #bbdefb;
$info-50: #e3f2fd;


//warning colors

$warn-900: #e65200;
$warn-800: #ef6d00;
$warn-700: #f57d00;
$warn-600: #fb8d00;
$warn-500: #ff9900;
$warn-400: #ffa826;
$warn-300: #ffb84d;
$warn-200: #ffcd80;
$warn-100: #ffe0b2;
$warn-50: #fff3e0;


.black {
    color: #000000;

}

.white {
    color: #FFFFFF;
}