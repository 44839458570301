@import "../../../../../assets/scss/variables";

.details-page {
  .content-holder {
    display: flex;
    gap: 40px;
  }

  .content-container {
    min-height: 690px;
    // border-color: $black-100 !important;
  }
}

.preview_modal_calender {
  font-family: "Poppins";
  border-radius: 16px;
  border: 1px solid #e4e7ec;
  // border-top-width: 0px;
  .table_container {
    border-radius: 16px;
  }
  table {
    margin-bottom: 0;
    tr {
      border-top: 0;
      th {
        &:last-child {
          border-right: 0;
        }
        border-top: 0;
      }
      td {
        &:first-child {
          border-left: 0;
        }
        &:last-child {
          border-right: 0;
        }
      }
    }
  }
  .leave_table_td {
    width: 100% !important;
  }
}
