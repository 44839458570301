@import "../../../../assets/scss/variables";


.details-page {
  .content-holder {
    display: flex;
    gap: 40px;

  }

  .content-container {
    min-height: 690px;
    // border-color: $black-100 !important;
  }
}