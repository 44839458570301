.regular-checkbox {
    -webkit-appearance: none;
    background-color: white;
    border: 1.5px solid #9d9d9d;
    box-shadow: none !important;
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    width: 22px;
    height: 22px;
    cursor: pointer;
    margin-right: 8px;
}


.filter-box {
    gap: 10px;
    padding: 24px;
    width: 400px;
    border: 1px solid #d9d9d9;
    // height: 350px;
    right: 0px;
    position: absolute;
    background: white;
    margin: 6px 0px;
    border-radius: 8px;
    box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 10%),
        0px 4px 6px -2px rgb(16 24 40 / 5%);
}

.regular-checkbox:active,
.regular-checkbox:checked:active {
    box-shadow: none;
}

.regular-checkbox:checked {
    background-color: #51c2bc;
    border: 1px solid #51c2bc;
    // box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
    color: #99a1a7;
}

.regular-checkbox:checked:after {
    content: "\2713";
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 3px;
    color: white;
}