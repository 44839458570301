.weekly-view-container {
  width: 100%;
  height: calc(100% - 165px);
  padding: 16px;;
  margin-bottom: 8px;
  position: relative;
  overflow: hidden;
  // padding: 0 25px;

  .add-week-button{
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 2px dashed #E4E7EC;
    border-radius: 16px;
    button{
      background: none;
      border: none;
      box-shadow: none;
      // padding: 0;
      // width: 100%;
      // height: 100%;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      &:hover{
        box-shadow: none;
      }
      span{
        color: #000;
      }
    }
  }

  .week-view-wraper {
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
    width: 100%;
    height: 100%;
    //min-height: 517px;
    border: 1px solid #e9e9e9;
    border-radius: 8px;

    &::-webkit-scrollbar {
      display: none;
    }

    table thead {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 70;
    }

    table tbody tr {
      // height: 132px;
      // min-height: 132px;
      overflow: hidden;
    }
  }
}

//======== header style========//

.week-view-wraper table thead tr th:not(:last-child) {
  border-right: 1px solid #e9e9e9;
}

.week-view-wraper table td {
  vertical-align: middle;
}

.week-view-wraper .sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  min-width: 250px;
  height: 100%;
  z-index: 22;
}

.first-col {
  width: 235px;
  min-width: 235px;
  max-width: 235px;
  height: 100%;
  left: 0px;
}

.branch-header {
  padding: 0px;
  // width: 237px;
  height: 58px;

  /* Colors/Grey/Grey 100 */

  background: #edf2f7;
}

.branch-name-heading {
  // position: relative;
  display: flex;
  align-items: center;
  padding: 16px 12px;
  // overflow: hidden;
  min-width: 234px;
  height: 58px;
  max-height: 58px;

  background: #eff1f7;

  .badge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    width: 32px;
    height: 22px;
    padding: 2px 8px;
    background: #e1f4f4;
    border-radius: 16px;
    color: #09988d;
  }
}

.branch-name-cell {
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  min-width: 237px;
  max-width: 237px;
  height: 132px;
  /* Black/Black 00 */
  cursor: pointer;
  background: #ffffff;
  /* Colors/Black/Black 100 */

  //border: 1px solid #e9e9e9;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
}

.row-1 {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  .row-badge {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-left: 18px;
    margin-right: 6px;
    /* Colors/Black/Black 500 */

    background: #7b7b7b;
  }

  span {
    color: #7b7b7b;
  }
}

.branch-status {
  padding: 4px 12px;
  margin-top: 16px;
  max-width: 70px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Colors/Success/Success 50 */
  // background: #e8f5e9;
  border-radius: 36px;
}

.week-schedular-slot-container {
  // display: flex;
  // flex-direction: column;
  width: 100%;
  min-width: 166px;
  height: 132px;
  background: #ffffff;
  /* Colors/Black/Black 100 */
  border-left: 1px solid #e9e9e9;
  height: 130px;
}

.week-day-heading-cell {
  overflow: hidden;
  height: 100%;
  background: #eff1f7;
  // border-left: 1px solid #e9e9e9;
}

.empty-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 10px;

  //width: 448px;
  //height: 373.39px;
  // background-color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  z-index: 2;
}
