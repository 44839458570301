.branchSlotDetailContainer {
  position: absolute;
  padding: 8px;
  margin: 9px 8px 7.89px 5.5px;
  cursor: pointer;
  width: 133px;
  height: 353px;

  background: #f9f9f9;
  /* Colors/Black/Black 100 */

  border: 1px solid #e9e9e9;
  border-radius: 8px;
}
