@import '../../../../assets/scss/variables';


.table-tab {
    background-color: $black-50;
}

.table-tab-active {
    background-color: white;
    border-bottom: 2px solid $primary-400;
}