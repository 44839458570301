.leave_filter {
  .auto-complete {
    margin-bottom: 6px;
    .ant-select {
      width: 100% !important;
      .ant-select-selector {
        border-radius: 10px;
        border: 1px #d0d5dd solid;
        padding: 6px 14px;
        height: auto;
        input {
          height: 100%;
          font-family: "Poppins";
        }
      }
      .ant-select-selection-placeholder {
        color: #9d9d9d;
        font-size: 14px;
        font-family: "Poppins";
        font-weight: 400;
      }
    }
  }
  .pharma_options_wrapper {
    padding-left: 0;
    margin-bottom: 0;

    li {
      padding: 8px;
    }
    .pharma_info {
      display: flex;
      align-items: center;
      i {
        margin-right: 8px;
        // img {
        //   width: 24px;
        //   height: 24px;
        //   border-radius: 50%;
        // }
      }
      p {
        color: #000;
        font-size: 14px;
        font-family: "Poppins";
        font-weight: 400;
      }
    }
  }
}

.ant-popover {
  &:has(.leave_filter) {
    .ant-popover-inner {
      width: 380px;
    }
  }
  &:has(.leave_dropdown) {
    .ant-popover-inner {
      width: 380px;
    }
  }

  .ant-popover-content {
    .ant-popover-inner {
      .ant-popover-inner-content {
        .leave_filter {
          .pharma_options_wrapper {
            li {
              .ant-checkbox-wrapper {
                .ant-checkbox-wrapper-checked {
                  .ant-checkbox-inner {
                    background-color: #e1f4f4 !important;
                    border-color: #09988d !important;
                    &::after {
                      border-color: #09988d !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.filter_select_field {
  .ant-select-selector {
    padding: 6px 8px;
  }
  .ant-select-selection-placeholder {
    padding-left: 6px;

    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
  }
}

.select-tag {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  background-color: #f5f5f5;
  border-radius: 8px;
  border: none;
  .tag-label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #101828;
    margin-bottom: 0;
  }
  .anticon {
    display: none;
    // svg {
    //   height: 14px;
    //   width: 14px;
    // }
    // color: #616161 !important;
  }
  button {
    padding: 0;
    margin: 0;
    height: auto;
    border: none;
    background: none;
    margin-left: 6px;
    // height: 12px;
    // width: 12px;
  }
}

.customInputWithTags {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  padding: 4px 8px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
