.personal-info-wrapper {
  padding: 16px;
  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 16px;
  }

  .full_name {
    p {
      font-family: "Poppins", sans-serif;
      font-size: 14px;

      font-weight: 400;
    }
  }
  .profile_image {
    width: 20px;
    height: 20px;
    margin: 0;
    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .info-list-wrap {
    .text-row {
      //   width: 60%;
      padding: 0;
      justify-content: flex-start !important;
      margin-bottom: 16px;
      .primary-text {
        width: 20%;
      }
    }
    .text-row:nth-child(2n + 2) {
      background-color: #fff;
    }
  }
}
.info-modal-row {
  .ant-input {
    padding: 10px 14px !important;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    color: #000;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-row {
    flex-direction: column;
    justify-content: flex-start;
  }

  .select-field {
    &:hover {
      .ant-select-selector {
        border-color: #d9d9d9 !important;
      }
    }
    .ant-select-selector {
      padding: 4px 14px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;

      height: auto;

      .ant-select-selection-item {
        color: #000;
      }
      input {
        // padding: 10px 14px;
        height: auto;
      }
    }
  }
  .ant-form-item-label {
    text-align: left;
    label {
      color: #000 !important;
      font-size: 14px;
      font-family: "Poppins", sans-serif !important;
      font-weight: 500 !important;
      &::after {
        display: none;
      }
    }
  }
}
