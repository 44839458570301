@import '../../../../../assets/scss/typography';

.RoleTabContainer {
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #e9e9e9;
}

.visibleNone {
  visibility: hidden !important;
}

.ant-collapse-header {
  background-color: #ffffff;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: 'Inter';
}

.css-dev-only-do-not-override-gz0wk6 {
  border: none !important;
}
.add-role-collapse{
  .ant-collapse-item{
    border-radius: 0px !important;
    }
    :where(.css-dev-only-do-not-override-gz0wk6).ant-collapse>.ant-collapse-item:last-child, :where(.css-dev-only-do-not-override-gz0wk6).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header{
      border-radius: 0px !important;
    }
    .ant-collapse-item-active{
      border-bottom: none !important;
    }
    .ant-collapse-content-box{
    padding: 0px !important;
    }
    .ant-collapse-header{
      padding: 12px 0px !important;
    }
    .ant-form-item{
      margin: 0px !important;
    }
    .ant-checkbox-wrapper{
      @extend .p2 ;
      color: #7B7B7B !important;
    
    }
    .ant-checkbox-wrapper-checked {
      color: #000000 !important;
    }

}
