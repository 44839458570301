.filterActionBtn {
  align-items: center;

  span {
    width: 100%;
    :hover {
      color: #51c2bc !important;
    }
  }
  svg {
    height: 100%;

    :hover {
      stroke: #51c2bc !important;
    }
  }
  :hover {
    border-color: #51c2bc !important;
    color: #51c2bc !important;
  }

}
// :global(.filterActionBtn) {
//     background-color: var(--secondary-color);
//     &:hover {
//       background-color: var(--secondary-hover-color) !important;
//       border-color: var(--secondary-hover-color) !important;
//     }
//   }
