.common_tab {
  width: 100%;
  padding: 12px 16px;
  border-radius: 16px;
  border: 1px solid #e4e7ec;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  &:hover {
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
  }
  .tab-header {
    margin-bottom: 12px;
    h5 {
      font-size: 14px;
      font-weight: 500;
      color: #101828;
    }
  }
  .icon_wrap {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cmn-db-box {
  border-radius: 16px;
  border: 1px solid #e4e7ec;
  background: #fff;
  height: 100%;
  max-height: 500px;
  min-height: 500px;
  overflow: auto;
}
.cmn-db-box::-webkit-scrollbar {
  width: 0; /* Set to 0 to hide the scrollbar */
}

/* Firefox */
.cmn-db-box::-moz-scrollbar {
  width: 0;
}

/* Other scrollbar styles (optional) */
.cmn-db-box::-webkit-scrollbar-thumb {
  background-color: #888;
}

.cmn-db-box::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.dashboard-table-container {
  .table-header {
    position: sticky;
    top: 0;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    z-index: 1;
  }
}
.dashboard-chart-container {
  padding: 16px 24px;
  padding-top: 0;
  .chart-header {
    position: sticky;
    padding-top: 16px;
    top: 0px;
    margin-bottom: 10px;
    background-color: #fff;
    h4 {
      margin-top: 4px;
    }
  }
}

.total-expence-chart-wrap {
  height: calc(100% - 44px);
  width: 100%;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;

  ul {
    li {
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      p {
        font-family: "Poppins", sans-serif;
        color: #434343;
      }
      span {
        line-height: normal;
        color: #262626;
        text-wrap: nowrap;
        font-weight: 500;
        font-size: 13px;
        width: 70px;
        text-align: end;
      }
      .progress-wrap {
        width: 55%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .progress-bar {
          margin-left: 10px;
        }
      }
    }
  }
}
.common-dashboard-table-wrapper {
  &:has(.ant-empty) {
    display: flex;
    height: calc(100% - 64px);
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  table {
    thead {
      tr {
        th {
          // position: relative;
          top: 64px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          &:first-child {
            border-left-width: 0;
          }
          &:last-child {
            border-right-width: 0;
          }
          border: 1px solid #e9e9e9;
        }
      }
    }

    tbody {
      tr {
        td {
          background-color: #fff;
          border: 1px solid #e9e9e9;
          &:first-child {
            border-left-width: 0;
            border-bottom-left-radius: 24px;
          }
          &:last-child {
            border-right-width: 0;
            border-bottom-right-radius: 24px;
          }
        }
        // &:last-child {
        //   td {
        //     border-bottom: 0;
        //   }
        // }
      }
    }
  }
}

.staff-chart-wrap {
  display: flex;
  align-items: center;

  .chart-wrapper {
    width: 60% !important;
    height: 100%;
    margin-right: 32px;
    position: relative;
    max-width: 300px;

    .chart-data {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        color: #555555;
        font-size: 12px;
        font-family: "Poppins", sans-serif;
        line-height: 20px;
      }
    }
  }

  .chart-details {
    display: flex;
    &:first-child {
      margin-bottom: 12px;
    }

    .list-dot {
      height: 10px;
      width: 10px;
      border-radius: 50px;
      display: block;
      margin-right: 8px;
      margin-top: 2px;
    }

    p {
      color: #555555;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.ant-popover {
  &:has(.filter-popup-wrapper) {
    max-width: 566px;
    width: 100%;
    .ant-popover-inner {
      padding: 0;
    }
    .popup-header,
    .popup-body,
    .popup-footer {
      padding: 16px;
    }
    .popup-header {
      border-bottom: 1px solid #e9e9e9;
      h5 {
        margin-bottom: 0;
      }
    }
    .popup-body {
      .ant-picker-input {
        input {
          font-family: "Poppins", sans-serif;
        }
      }
    }
    .popup-footer {
      border-top: 1px solid #e9e9e9;
      .secondary-btn {
        border: 1px solid #51c2bc;
        &:hover {
          border: 1px solid #e9e9e9;
          span {
            color: #262626;
          }
        }
        span {
          color: #51c2bc;
        }
      }
      .ant-btn {
        min-width: 168px;
      }
    }
  }
}
.ant-picker-year-panel,
.ant-picker-month-panel {
  .ant-picker-cell {
    &::before {
      top: 16px !important;
    }

    &.ant-picker-cell-range-hover,
    &.ant-picker-cell-range-hover-start,
    &.ant-picker-cell-range-hover-end {
      &::after {
        top: 16px !important;
      }
    }
  }
}
.commondata-tab-sekeleton {
  max-height: 112px;
  height: 100%;
  .ant-skeleton-paragraph {
    margin-left: -45px;
  }
}
.skeleton-with-chart {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 50px !important;
  .ant-skeleton-title {
    position: absolute !important;
    top: 0;
    left: 12px;
  }
  ul {
    &.ant-skeleton-paragraph {
      li {
        &:first-child {
          margin-block-start: 16px;
        }
        &:nth-child(2n) {
          width: 20% !important;
        }
        &:nth-child(2n + 1) {
          width: 60% !important;
        }
      }
    }
  }
}
.chart-skeleton {
  ul {
    &.ant-skeleton-paragraph {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: row-reverse;
      li {
        &:first-child {
          margin-block-start: 16px;
        }
        &:nth-child(2n) {
          width: 50% !important;
        }
        &:nth-child(2n + 1) {
          width: 35% !important;
        }
        &:nth-child(3n) {
          width: 40% !important;
        }
      }
    }
  }
}
.table-skeleton {
  position: relative;
  .ant-skeleton-avatar {
    position: absolute !important;
    top: 12px;
    right: 12px;
  }
  ul {
    li {
      height: 45px !important;
    }
  }
}
