@import "../../assets/scss/variables";

.detail-tab-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 16px;
  width: 100%;
  min-width: 212px;
  gap: 20px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  cursor: pointer;
}

.detail-tab-container:hover {
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
}

.breadCrumps-detail-box {
  padding: 6px 8px;
  color: #2e7d31;
  background: #e8f5e9;
  border-radius: 4px;
}

.breadCrumps-detail-box-error {
  padding: 6px 8px;
  background: #ffebee;
  border-radius: 4px;
  color: #ef5350;
}

.breadCrumps-role-detail {
  color: #7b7b7b;
}

.center-border {
  width: 2px;
  height: 24px;

  background: #d9d9d9;
  border-radius: 24px;
  //margin-left: 25px;

  // right: 0px;
}

.tabs-seprator {
  @extend .center-border;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.table-graph-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #ffffff;

  /* Colors/Black/Black 100 */

  border: 1px solid #e9e9e9;
  border-radius: 8px;
}

.primary-color {
  color: #51c2bc;
}

.tooltipArrow {
  height: auto;
  position: absolute;
  bottom: -20px;

  left: 20px;
  width: 0;
  height: 0;
  border-color: #51c2bc transparent transparent transparent;
  border-width: 10px;
  border-style: solid;
}

.dashboard-table-links {
  color: #51c2bc;
  cursor: pointer;
  border-bottom: none;
  text-decoration: none;

  &:hover {
    // text-decoration: underline;
    color: #51c2bc;
  }

  &:focus {
    // text-decoration: underline;
    color: #51c2bc;
  }
}

.danger-links {
  color: $error-500;
  cursor: pointer;
  border-bottom: none;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: $error-500;
  }

  &:focus {
    text-decoration: underline;
    color: $error-500;
  }
}

.ellipse-flag {
  width: 14px;
  height: 14px;
  border-radius: 50px;
  margin-right: 6px;
background: #51c2bc;
}
// .dashboard-rangepicker {
//   border-radius: 24px;
//   padding: 10px 12px;
//   height: auto !important;
//   justify-content: center;
//   .ant-picker-active-bar{
//     margin-inline-start: 34px;
//   }
//   .ant-picker-input {
//     justify-content: flex-end;
//   }
//   .ant-picker-input {
//     width: 87px;
//     input {
//       color: #6f6f6f;
//       font-family: "Poppins", sans-serif;
//       text-align: left;
//     }
//   }
//   .ant-picker-range-separator + .ant-picker-input {
//     input {
//       text-align: right;
//     }
//   }
// }

.range-picker-wrap {
  .calendar,
  .dropdown {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .calendar {
    left: 10px;
  }
  .dropdown {
    right: 7px;
  }
}
