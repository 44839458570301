@import "../../../assets/scss/variables";

.content-container {
    width: 100%;
    min-height: 735px;
    background: $white;
    box-sizing: border-box;

    &.fluid {
        width: 100%;
        margin: 0 auto;
        max-width: 1320px;
    }

    &.transparent {
        border: none;
    }

    &.xSmallPaddings {
        padding: 8px 10px;
    }

    &.autoHeight {
        height: auto;
    }
}