.action-btn {
  position: relative;

  Button {
  //  color: #51c2bc !important;
    padding: 0px !important;
    margin: 0px !important;
    height: 40px;
    min-width: 100px;
    span {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  :hover {
    border: 1px solid #51c2bc !important;
    color: #51c2bc !important;
  }
  :focus {
    border: 1px solid #51c2bc !important;
    color: #51c2bc !important;
  }
}
