@import "./../../../assets/scss/variables";

.custom-checkbox {
    input:checked~label:before {
        background: $primary-300 !important;
        border-color: $primary-300 !important;
        border-radius: 2px;
    }

    input:checked~label:after {
        color: $white !important;
        font-size: 8px !important;
    }

    label {
        font-size: 16px !important;
        line-height: 19px !important;
        color: $black-500 !important;
        text-transform: capitalize;
        padding-left: 22px !important;
    }

    &.small {
        &.ui.checkbox {
            min-height: unset;
        }

        &.ui.checkbox .box:before,
        &.ui.checkbox label:before,
        &.ui.checkbox label:after {
            top: 2px;
        }

        input~label:before,
        input~label:after {
            width: 12px !important;
            height: 12px !important;
        }

        label {
            font-size: 12px !important;
            line-height: 14px !important;
            font-weight: 500 !important;
            color: $black !important;
            text-transform: capitalize;
            padding-left: 18px !important;
            margin-bottom: 0 !important;
        }
    }
}

.group-checkbox-buttons {
    label {
        font-size: 16px;
        line-height: 22px;
        color: $black;
    }

    &__buttons-list {
        display: flex;
        margin-top: 8px;

        .button {
            text-transform: capitalize;
            border: 1px solid $locum-blue;
            width: 60px;
            height: 40px;
            margin: 0;

            &+.button {
                margin-left: 10px;
            }
        }
    }
}

.radio-group {
    &.hidden {
        display: none;
    }

    .group-label {
        display: block;
        font-style: normal;
        font-size: 12px !important;
        line-height: 14px !important;
        font-weight: 600 !important;
        text-transform: capitalize;
        color: $black !important;
        margin-bottom: 8px !important;
    }

    &__inner {
        display: flex;
        gap: 10px 25px;
        flex-wrap: wrap;
    }

    .checkbox {
        input {
            width: 16px;
            height: 16px;
            background: $grey-500 !important;
        }

        input:checked~label:before {
            color: $primary-300 !important;
        }

        input:checked~label:after {
            background-color: $locum-blue !important;
            background-color: $primary-300 !important;
            color: $primary-300 !important;
        }

        label {
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            line-height: 19px;
            text-transform: capitalize;
            padding-left: 22px !important;
            color: $grey-500;

            &:before {
                border-color: $grey-500;
            }
        }
    }
}