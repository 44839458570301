.pending-job-poppover{
  width: 296px;
  height: 80px;

  /* Desktop/Paragraph/P3 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* Colors/Black/Black 700 */

  color: #434343;
}
