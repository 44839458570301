.locum-btn {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 50px !important;
  background-color: #02988e;
  // padding: 16px;
  span {
    color: #fff !important;
  }
}
.common-toolbar-wrapper {
  .main-toolbar-content {
    flex-direction: row-reverse;
  }
}
.locum-radio-group {
  .ant-radio-button-wrapper {
    height: 100%;
    padding: 4px 4px;
    &.ant-radio-button-wrapper-checked {
      border-color: #d9d9d9;
      span:not([class]) {
        background-color: #02988e;
        color: #fff;
      }
    }
    span:not([class]) {
      // background-color: #026aa2;
      transition: all 0.3s ease-in-out;

      height: 100%;
      width: 100%;
      display: block;
      border-radius: 50px;
      padding: 4px 12px;
      font-size: 16px;
      font-weight: 500;
      color: #667085;
      font-family: "poppins", sans-serif;
      // margin: 4px;
      // margin: 0 -4px;
    }
    &:first-child {
      border-start-start-radius: 50px;
      border-end-start-radius: 50px;
      border-right: none;
    }
    &:last-child {
      border-end-end-radius: 50px;
      border-start-end-radius: 50px;
      border-left: 0;
      &::before {
        display: none;
      }
    }
  }
}
.locum-table-container {
  border-radius: 12px;
  border: 1px solid #e9e9e9;
  background-color: #fff;
  margin-bottom: 100px;

  .locumdate {
    p {
      font-size: 14px;
      font-weight: 500;
    }
    span {
      font-size: 12px;
      font-weight: 400;
      color: #555555;
    }
  }

  .ratings,
  .total,
  .locum,
  .branch {
    .ant-typography {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      color: #344054;
      font-weight: 400;
    }
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #344054;
    font-weight: 400;
  }

  table {
    thead {
      tr {
        th {
          // position: relative;
          top: 0px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;

          &:first-child {
            border-left-width: 0;
          }
          &:last-child {
            border-right-width: 0;
          }
          border: 1px solid #e9e9e9;
        }
      }
    }

    tbody {
      tr {
        td {
          background-color: #fff;
          border: 1px solid #e9e9e9;
          padding: 12px;
          &:first-child {
            border-left-width: 0;
            border-bottom-left-radius: 24px;
          }
          &:last-child {
            border-right-width: 0;
            border-bottom-right-radius: 24px;
          }
        }
        // &:last-child {
        //   td {
        //     border-bottom: 0;
        //   }
        // }
      }
    }
  }
  .ant-tag {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    border: none;
    border-radius: 50px;
    padding: 6px 12px 6px 10px;
    width: auto;
    // max-width: 153px;
    svg {
      margin-right: 6px;
      width: 14px;
      height: 14px;
    }
    span {
      font-family: "Poppins", sans-serif;
      font-size: 12px;
      font-weight: 500;
    }

    &.unfilled {
      border: 1px dashed #555555;
      background-color: #fff;
    }
    &.pending {
      background: #feefda;
      span {
        color: #db810b;
      }
    }
    &.assigned,
    &.payment-pending {
      background: #f0f9ff;
      span {
        color: #026aa2;
      }
    }
    &.cancelled {
      background: rgba(211, 47, 47, 0.08);
      span {
        color: #d32f2f;
      }
    }
    &.completed {
      background: #ecfdf3;
      span {
        color: #027948;
      }
    }
  }
}
.ant-popover-inner {
  &:has(.locum-job-filter-popover) {
    max-width: 400px;
    min-width: 400px;
  }
}
.locum-job-filter-popover {
  .locum-filter-form {
    .cancel-btn {
      height: auto;
      padding: 8px 16px;
      // max-width: 145px;
      width: 100%;
      border-radius: 8px;
      margin-right: 12px;
      border: 1px solid #475467;
      color: #475467 !important;
      display: flex;
      justify-content: center;
    }
    .ant-form-item {
      margin-bottom: 14px;
    }
    .ant-form-item-label {
      label {
        font-family: "Poppins", sans-serif;
        color: #000;
      }
    }
    .ant-select-selector {
      height: 36px;
      .ant-select-selection-placeholder {
        font-family: "Poppins", sans-serif;
      }
    }
    .apply-btn {
      height: auto;
      padding: 8px 16px;
      // max-width: 145px;
      width: 100%;
      border-radius: 8px;
      margin-right: 12px;
      border: 1px solid #02988e;
      background-color: #02988e;
      display: flex;
      justify-content: center;
      span {
        color: #fff !important;
      }
    }
  }
}
