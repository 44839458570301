@import "../../../assets/scss/variables";

.selection-tab {
  gap: 50px;
  border: 1px solid $input-border-color;
  max-width: 543px;
  background-color: #fff;
  padding: 16px;
  border-radius: 16px !important;

  &:first-child {
    margin-bottom: 24px;
  }

  .heading4 {
    font-size: 16px;
    font-weight: 500;
  }
  .t1 {
    color: #000;
    line-height: 2;
  }

  .ant-radio-inner {
    width: 20px;
    height: 20px;
  }

  .ant-radio-checked {
    .ant-radio-inner {
      background-color: #e1f4f4 !important;
      border: 1px solid #09988d !important;
      &:after {
        background: #09988d !important;

        width: 20px;
        height: 20px;

        margin-block-start: -10px;
        margin-inline-start: -10px;
      }
    }
  }
}

.active-tab {
  border-color: #09988d !important;
}

.sign_up_page {
  &:has(.locum_signup_new) {
    height: 920px !important;
  }

  .slider_wrapper {
    height: 100%;
    width: 99.7% !important;
  }
}
