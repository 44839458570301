@import "../../../assets/scss/_typography.scss";
@import "../../../assets/scss/variables";

.main-table thead tr th {
  padding: 15px 25px;
  background: #edf2f7 !important;
  width: auto;
  font-weight: 500;
  color: $black;
}

.main-table tbody tr:nth-of-type(2n + 2) {
  background: #f9f9f9;
}

.main-table {
  margin: 0 !important;
  box-shadow: none !important;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -16px;
  left: 0;
  height: 20px;
  border-radius: 5px;
  width: 20px;
  border: 1px solid #d3d1d1;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #51c2bc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.table-fix-header {
  // overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  thead th {
    position: sticky;
    top: 0;
    z-index: 5;
    height: 52px !important;
  }

  tbody tr {
    height: 52px !important;

    td {
      @extend .p3;
      height: 52px !important;
      padding: 15px 25px;
      vertical-align: middle;
    }
  }
}

.primary-button-component {
  background-color: #51c2bc !important;
  color: white !important;
  padding: 12px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  display: flex !important;
  gap: 10px !important;
  align-items: center !important;
  height: 40px;
  border-radius: 8px !important;
  font-size: 16px !important;
  width: 100%;
  text-align: center;
  white-space: nowrap;
}

.secondary-button-component {
  background-color: transparent !important;
  padding: 12px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  display: flex !important;
  grid-gap: 10px !important;
  gap: 10px !important;
  height: 40px;
  align-items: center !important;
  border-radius: 8px !important;
  border: 1px solid #d9d9d9 !important;
  font-size: 16px !important;
  width: 100%;
  text-align: center;
  white-space: nowrap;
}
.action-btn {
  position: relative;

  Button {
    //  color: #51c2bc !important;
    padding: 0px !important;
    margin: 0px !important;
    height: 40px;
    min-width: 100px;
    span {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  :hover {
    border: 1px solid #51c2bc !important;
    color: #51c2bc !important;
  }
  :focus {
    border: 1px solid #51c2bc !important;
    color: #51c2bc !important;
  }
}

.css-14h4o58-menu {
  z-index: 200 !important;
}
