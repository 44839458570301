.ant-table-container {
  border-start-start-radius: 8px;
  border-start-end-radius: 8px;
  border-end-end-radius: 8px;
  border-end-start-radius: 12px;
}
.document_table_container::-webkit-scrollbar {
  width: 0rem; /* Adjust as needed */
}

/* For WebKit-based browsers (e.g., Chrome, Safari) */
.document_table_container::-webkit-scrollbar-thumb {
  background-color: transparent; /* Make the thumb transparent */
}
.document_table_container::-webkit-scrollbar-thumb {
  background-color: transparent; /* Make the thumb transparent */
}
.document_table_container {
  border-radius: 16px;
  height: calc(100vh - 330px);
  overflow: auto;
  table {
    font-family: "Poppins";
    thead {
      position: sticky;
      top: 0;
      z-index: 1;
      tr {
        background: #f5f8f9 !important;
      }
      th {
        background: #f5f8f9 !important;
      }
    }
    tbody {
      tr {
        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 12px;
            }
            &:last-child {
              border-bottom-right-radius: 12px;
            }
          }
        }
      }
      td {
        vertical-align: middle;
        padding: 12px 12px;
      }
    }
  }
  .file_name {
    display: flex;
    align-items: center;

    i {
      margin-right: 8px;
      display: flex;
      align-items: center;
      width: 32px;
      height: 32px;
      img {
        // height: 100%;
        width: 50px;
        object-fit: contain;
      }
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
    }
    span {
      color: #555555;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .document_type {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  .date_render {
    color: #344054;
    font-size: 14px;
    font-weight: 400;
  }
}
.upload_btn {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  box-shadow: none !important;
  align-items: center;
  img {
    margin-right: 10px;
  }
  span {
    color: #09988d !important;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 500;
    font-family: "Inter";
  }
  &:hover {
    border: 1px solid transparent !important;
    box-shadow: none !important;
  }
}
.input_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9fafb;
  padding: 12px;
  border-radius: 12px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  .input_file_name {
    background-color: #fff;
    padding: 11.1px 18px;
    border-radius: 8px;
    border: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 332px;
    width: 100%;
    margin-right: 16px;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
    }
    p {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 500;
      color: #262626;
    }
  }

  .ant-select {
    padding: 5px 14px !important;
    height: auto !important;
    border-radius: 8px;
    border: 1px solid #e9e9e9;
    background-color: #fff;

    max-width: 332px;
    width: 100%;
    margin-right: 16px;
    .ant-select-selector {
      padding-left: 0;
    }
    .ant-select-selection-placeholder {
      color: #7b7b7b;
      font-size: 14px;
      font-weight: 400;
      font-family: "Poppins";
    }
    .ant-select-selection-item {
      color: #101828;
    }
  }
  .del_btn {
    padding: 9px 9px;
    background: #fff;
    border: 1px solid #d6d9e1;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.action_wrapper {
  button {
    &.btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      img {
        width: 20px;
        height: 20px;
      }
      &:focus,
      &:not(:active) {
        border: none;
      }
    }
  }
}
.ant-tooltip-inner {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
}
.dragndrop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  border: 1px dashed #2bb4ac;
  border-radius: 8px;
  padding: 18px 20px;
  font-family: "Poppins";
  background: rgba(225, 244, 244, 0.29);
  &.upload_box {
    border-radius: 8px;
    border: 1px solid #e4e7ec;
    background: #fff;
    padding: 15.5px 24px;
    margin-bottom: 0;
    flex-direction: row;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
    &:has(.completed) {
      border: 1px solid #51c2bc;
      .cross {
        align-self: center;
      }
    }

    &:has(.failed) {
      border: 1px solid #f28e89;
      background-color: #ffebeb;
      padding: 16px;
      .filename {
        color: #c8352f;
      }
      .failed {
        color: #ec5d56;
      }
    }
    figure {
      margin-bottom: 0;
    }
    .file_input_area {
      // padding: 0 12px 4px;
      color: #667085;
    }
    p {
      padding-left: 12px;
    }
    .filetypes {
      color: #667085;
      text-align: left;
    }
    span {
      padding-right: 4px;
    }
  }

  .ant-upload-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: -18px -20px 0 -18px;

    .ant-upload-drag {
      border: none;
      background: none;
    }
    .ant-upload-drag-container {
      display: flex;
      justify-content: center;
    }
  }
  .cross {
    padding: 0;
    img {
      width: 18px;
      height: 18px;
    }
  }
  .completed {
    color: #09988d;
    font-size: 14px;
    font-weight: 400;
    font-family: "Inter";
  }
  .icon_wrapper {
    margin: 0;
    margin-bottom: 19px;
    border: 6px solid rgba(225, 244, 244, 0.63);
    img {
      height: 20px;
      width: 20px;
    }
  }
  .file_input_area {
    display: inline-block;
    padding: 0px 20px 8px 12px;

    // background-color: #0074c2;
    color: #222;
    font-size: 14px;
    font-weight: 400;
    // border: 1px solid #0074c2;
    button {
      padding: 0;
      padding-right: 4px;
      margin: 0;
      &:focus,
      &:not(:active) {
        border: none;
      }
    }
    span {
      cursor: pointer;
      text-decoration: underline;
      color: #09988d;
      font-weight: 500;
      &.filename {
        color: #344054;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
      }
    }
  }
  .filetypes {
    font-size: 12px;
    font-weight: 400;
    color: #909090;
    text-align: center;
  }
  .filesize {
    font-size: 14px;
    font-weight: 400;
    color: #667085;
    font-family: "Inter";
    margin-bottom: 0;
  }
  .ant-progress-outer {
    margin-inline-end: calc(-4em - 8px) !important;
    padding-inline-end: calc(4em + 8px) !important;
    padding-left: 10px;
  }
  .ant-progress-bg {
    background-color: #09988d;
  }
  .ant-progress-text {
    width: 4em;
    color: #344054;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
  }
  .file_input_area input {
    display: none;
  }
}
.content-container {
  border: 1px solid #e9e9e9;
  border-radius: 8px;
}
.staff_document_tab {
  .ant-tabs-content-holder {
    margin: 0 16px 16px 16px;
  }
}
.ant-select-dropdown {
  z-index: 1199;
}
.ant-picker-dropdown {
  z-index: 1199;
}
.ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    color: #000000 !important;
    font-weight: 400;
    font-size: 14px;
  }
}
.delete_document {
  color: #101828;
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins";
}
.fileupload_form {
  .ant-form-item-control-input-content {
    label {
      font-family: "Poppins";
      font-size: 12px;
      font-weight: 500;
      color: #000;
      width: 100%;
      margin-bottom: 6px;
    }
  }
  .ant-form-item {
    margin-bottom: 12px;
  }
  .ant-select {
    padding: 5px 14px !important;
    height: auto !important;
    border-radius: 8px;
    border: 1px solid #e9e9e9;
    background-color: #fff;

    width: 100%;

    .ant-select-selector {
      padding-left: 0;
    }
    .ant-select-selection-placeholder {
      color: #7b7b7b;
      font-size: 14px;
      font-weight: 400;
      font-family: "Poppins";
    }
    .ant-select-selection-item {
      color: #000;
    }
  }
  .ant-picker {
    padding: 9px 14px !important;
    height: auto !important;
    border-radius: 8px;
    border: 1px solid #e9e9e9;
    background-color: #fff;
    width: 100%;

    .ant-picker-input {
      input {
        &::placeholder {
          font-size: 14px;
          font-weight: 400;
          font-family: "Poppins";
          color: #7b7b7b;
        }
      }
    }
  }
}
