.date-picker-container {
  //padding: 8px;
  //   min-width: 360px;
  display: grid;
  grid-template-columns: auto auto auto;
  max-height: 40px;
  //width: 100%;
  /* Black/Black 00 */

  background: #ffffff;
  /* Colors/Black/Black 100 */

  border: 1px solid #e9e9e9;
  border-radius: 8px;

  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  span {
    white-space: nowrap;
  }
  .ant-picker .ant-picker-input > input {
    width: auto !important;
    text-align: center !important;
    min-width: 110px;
  }
  .ant-picker-input {
    padding: 0 6px;
  }

  .leftArrow {
    cursor: pointer;
    margin-right: 8px;
  }

  .rightArrow {
    cursor: pointer;
    margin-left: 8px;
  }
}
