.slotDetailContainer {
  position: relative;
  //   width: 568px;
  // min-width: 157px;
  //   width: 100%;
  height: 28px;
  margin: 5px;
  padding: 0px 10px;
  // overflow: hidden;
  /* Colors/Black/Black 50 */

  background: #f5f5f5;
  border-radius: 4px;
  cursor: pointer;
  // display: block;
}