@import "../../../assets/scss/variables";

.navbar-link {
  color: $black-600;
  padding: 18px;

  @media (max-width: 1440px) {
    padding: 18px 8px;
  }

  &:hover {
    color: $black-900;
  }
}

.admin_nav_icons {
  padding: 10px;

  @media (max-width: 1440px) {
    padding: 4px;
  }
}
.logout-btn{
  
}

.navbar-link.active {
  color: $black-900;
  background-color: $white !important;
  border-bottom: 2px solid $primary-400;
}

.header-content {
  position: absolute;
  text-align: left;
  max-width: 30.46vw;
  left: 7.32vw;
  top: 30vh;
}

.laptop-size {
  width: 50.9%;
  position: absolute;
  top: 30vh;
  right: 5vw;
}

.background-image {
  width: 100%;
  height: 100vh;
  position: relative;
}

.navbar-name {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 60px;
}

.border-bottom-header {
  background: #ffffff;
  border-bottom: 1px solid #eaeaea;
}

.profile-box-border {
  width: 2px;
  height: 32px;
  margin-right: 15px;
  background: #e9e9e9;
  border-radius: 24px;
  margin-left: 8px;
}

.user-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  cursor: pointer;
  padding-right: 8px;
}

.notificationContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 8px;
  gap: 16px;

  width: 412px;
  height: 504px;

  /* White */

  background: #ffffff;
  border-radius: 8px;
  position: relative;
}

.inner-container-wrapper {
  width: 100%;
  height: 452px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.notification-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 100%;
}

.readed-notification {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 12px;

  width: 100%;
  min-height: 79px;
  height: auto;

  background: #ffffff;
  border-bottom: 1px solid #e9e9e9;
}

.unreaded-notification {
  @extend .readed-notification;
  background: #e1f4f4;
  border-radius: 8px;
  border-bottom: none;
}

.sticky-header {
  position: sticky;
  top: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 500;
}

.header-component-container {
  .ant-popover-arrow {
    right: 30px !important;
  }
  padding: 0 24px;
  @media (max-width: 1440px) {
    padding: 0 12px;
  }
}

.main-banner {
  margin: auto;
  text-align: center;
  background-color: $warn-50;
  color: $warn-700;
  width: fit-content;
  padding: 4px 50px;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
}
