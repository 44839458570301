@import "../../../../assets/scss/variables";

.table-tab {
  background-color: $black-50;
}

.table-tab-active {
  background-color: white;
  border-bottom: 2px solid $primary-400;
}

.star-simple {
  cursor: pointer;

  &.orange-star {
    path {
      fill: #ffc500;
    }
  }
}

.ant-rate-star.ant-rate-star-full i svg {
  width: 150px;
  height: 200px;
  /* margin: -3.5px; */
}

.ant-rate-star.ant-rate-star-zero i svg {
  width: 150px;
  height: 200px;
  /* margin: -3.5px; */
}

.ant-rate-star.ant-rate-star-half.ant-rate-star-active i svg {
  width: 150px;
  height: 200px;
  /* margin: -3.5px; */
}

.ant-rate-star.ant-rate-star-full,
.ant-rate-star.ant-rate-star-zero,
.ant-rate-star.ant-rate-star-half.ant-rate-star-active {
  transition: transform 0s;
}

.ant-rate-star.ant-rate-star-half.ant-rate-star-active:hover {
  transform: scale(0.91);
}

.ant-rate-star.ant-rate-star-full:hover {
  transform: scale(0.91);
}

.ant-rate-star.ant-rate-star-zero:hover {
  transform: scale(0.91);
}