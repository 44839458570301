.month-view-wraper {
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
    width: 100%;
    height: auto;
    border: 1px solid #e9e9e9;
    border-radius: 8px;

    .month-day-heading-cell {
        vertical-align: middle;
        overflow: hidden;
        height: 100%;
        background: #eff1f7;
    }

    .table-row {
        height: 156px;
        max-height: 156px;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        //border: 1px solid red;
    }

    .month-schedular-slot-container {
        position: relative;
        width: 100%;
        min-width: 166px;
        height: 132px;
        background: #ffffff;
        /* Colors/Black/Black 100 */
        border-left: 1px solid #e9e9e9;
        height: 130px;
        .month_view_date{
            display: block;
            width: 100%;
            text-align: center;
        }
    }

    .sticky-row {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        right: 0;
        z-index: 400;
    }
}

.month-view-wraper table thead tr th:not(:last-child) {
    border-right: 1px solid #e9e9e9;
}

.month-view-wraper table td {
    vertical-align: middle;
}