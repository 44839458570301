.popover {

  padding: 16px;

  min-width: 328px !important;
  // max-height: 468px !important;
  /* Black/Black 00 */
  background: #ffffff !important;
  /* drop shadow */

  box-shadow: 0px 6px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.1) !important;
  border-radius: 8px !important;
  overflow: hidden;
  text-overflow: ellipsis;

}

.slotDetailForLocumFlow {
  .popover {
    min-width: 166px !important;

  }

}

.moreDetailPoppover {
  .popover {
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

}


.poppover-container-header {
  width: 100%;
  overflow: hidden;

  .profile-info {
    margin-left: 8px;
   
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.poppover-badge {
  width: 8px;
  height: 8px;

  /* Colors/Success/Success 400 */

  background: #66bb69;
  border-radius: 26px;
  margin-right: 4px;
}

.poniter {
  cursor: pointer;
}

.poppover-content {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  .profile-row {
    margin-top: 16px;

    .info-heading {
      color: #7b7b7b;
    }
  }
}

/////============= More detail modal =============== //////

.branch-profile-detail {
  width: 80%;
}

.slotCategory {
  // height: 32px!important;
  width: 100%;
  margin-top: 20px;

  .slot-category-option {
    width: 100%;
    cursor: pointer;
  }
}

.CategoryDetailContainer {
  width: 100%;
  margin-top: 10px;

  .detail-row {
    width: 100%;
    height: 32px;
    padding: 0px 12px;

    div {
      width: 50%;
    }
  }

  .break-time-row {
    background: #ffffff;
    /* Colors/Primary/Primary 100 */

    border: 1px solid #b5e4e2;
    border-radius: 8px;
    width: 100%;
    height: 32px;
    padding-right: 24px;

    .verticleline {
      width: 4px;
      height: 18px;
      margin: 0px 10px;
      /* Colors/Primary/Primary 300 */

      background: #51c2bc;
      border-radius: 8px;
    }
  }

  .last-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    width: 100%;
    height: 36px;

    /* Colors/Primary/Primary 50 */

    background: #e1f4f4;
    border-radius: 8px;
    padding-right: 10px;

    div {
      width: 50%;
    }
  }
}