@import "../../../assets/scss/variables";

.branch-card {
  position: relative;
  width: 150px !important;
  overflow: hidden;
  padding-top: 24px !important;
  margin: auto !important;
  box-shadow: none !important;

  &.ui.card > :first-child,
  .ui.cards > .card > :first-child {
    border-radius: 50% !important;
    // border: 4px solid #c0d45c !important;
  }

  .text-content {
    text-align: center;
  }

  .text-content .header {
    height: auto !important;
  }

  .image {
    width: fit-content;
    height: 150px !important;
    display: flex !important;
    align-items: center;
    transition: all 0.3s ease;
    opacity: 0;
    margin: 0 auto;

    &.error {
      position: relative;
      width: 100%;
      height: 162px;
      background: $lighten-grey;
      opacity: 1;

      img {
        display: none;
      }
    }

    img {
      display: block;
      max-width: 100% !important;
      width: auto !important;
      height: 100% !important;
      transition: all 0.3s ease;
    }
  }

  .profile_img_wrapper {
    background-image: url("../../../assets/icons/profile-rign.svg");
    background-repeat: no-repeat;
    // background-position: 100% 100%;
    background-size: contain;

    &.notVerified {
      background-image: url("../../../assets/icons/notVerified.svg");
    }
    img {
      padding: 4px;
    }
    .status {
      position: absolute;
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);
      border: 1px solid #fff;
      background-color: #e8f5e9;
      border-radius: 50px;
      padding: 1px 7.5px;
      font-family: "Poppins";
      font-size: 10px;
      font-weight: 500;
      color: #388e3b;

      &.notVerified {
        background-color: #ffebee;
        color: #b71b1c;
      }
    }
  }
  &__title {
    background: transparent !important;
    text-transform: capitalize;
    font-family: inherit !important;
    font-style: normal;
    font-weight: bold;
    font-size: 28px !important;
    line-height: unset !important;
    color: $black !important;
    height: auto !important;
    word-break: break-word;

    p {
      margin: 0 !important;
    }

    .id {
      font-size: 16px;
      line-height: 28px;
      color: $grey;
    }
  }
  .header {
    p {
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 500 !important ;
      color: #000;
    }
  }
  .description {
    font-family: inherit !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 22px !important;
    color: $black !important;
    margin: 4px 0 24px !important;
    text-transform: capitalize;
    p {
      color: #555555;
      font-size: 12px;
      font-weight: 400;
    }
    .email {
      text-transform: lowercase;
      margin: 0;
      word-break: break-word;
    }
  }
  .gphc_no {
    margin-inline-end: 0;
    width: 100%;
    margin: 0 auto;
    background: #f5f5f5;
    border-radius: 50px !important;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins";
    color: #101828;
    text-align: center;
    border: none;
  }
  .content {
    border: none !important;
    padding: 16px 0 0 0 !important;

    &.extra {
    }
  }
}
.verify_btn {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: #51c2bc;
  span {
    color: #fff !important;
  }
}

.ant-list {
  margin-bottom: 24px;
}
.modal_text {
  p {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    font-family: "Poppins";
    padding: 24px 0;
  }
  .ant-list-item {
    border-block-end: none;
    padding: 2px 0;
    strong {
      // font-family: "Poppins";
      color: #000;
      font-weight: 400;
    }
    span {
      font-family: "Poppins";
      color: #000;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.img_wrap {
  display: flex;
  justify-content: center;
  margin: 0;
}
.ant-typography {
  font-family: "Poppins";
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.modal-footer {
  .ant-btn {
    border-radius: 8px !important;
  }
}
.verify_modal {
  .modal-dialog {
    max-width: 680px;
  }
}

.ant-tooltip-inner {
  padding: 12px !important;
  p {
    font-size: 12px;
    font-weight: 500 !important;
  }
  span {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 400;
  }
}
