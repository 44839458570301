@import "../../../assets//scss//variables";

.custom-tabs {
    position: relative;

    .edit-info {
        position: absolute;
        right: 35px;
        z-index: 99;
        top: 30px;
        // cursor: pointer;
        color: $primary-500;
        font-weight: normal;
    }

    .ant-tabs-nav {
        border-color: $grey-100;
        padding: 20px 20px 0 31px;
        // margin-bottom: 0;

        .ant-tabs-tab {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $black-600;
        }
    }

    .ant-tabs-content-holder {
        background-color: $white !important;
        padding: 0 !important;
        margin: 30px;
        border: none;
        box-shadow: none;
    }
}