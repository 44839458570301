@import "../../assets/scss/typography";
.ant-form-item-label > label.ant-form-item-required::before {
  content: none;
}

.ant-form-item .ant-form-item-label > label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  /* Black/Black 500 */

  color: #7b7b7b;
}
.rating-form-item {
  margin-bottom: 12px;
  .ant-form-item-label > label {
    color: #000;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
}
.ant-input {
  @extend .t1;
}
.ant-select-dropdown .ant-select-item-option-content {
  @extend .t1;
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  @extend .t1;
  line-height: 30px;
}
.absolute_message_btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 56px;
  height: 56px;
  border-radius: 50px !important;
  background-color: #e1f4f4 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  &.ant-btn-default {
    border-radius: 50px !important;
  }
}
.absolute_top_img {
  position: absolute;
  top: 0;
  left: 0;
}
.absolute_bottom_img {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}
.slider_wrapper::-webkit-scrollbar {
  width: 0.5rem; /* Adjust as needed */
}

/* For WebKit-based browsers (e.g., Chrome, Safari) */
.slider_wrapper::-webkit-scrollbar-thumb {
  background-color: transparent; /* Make the thumb transparent */
}
.slider_wrapper::-webkit-scrollbar-thumb {
  background-color: transparent; /* Make the thumb transparent */
}

.slider_wrapper {
  // height: 76vh;
  overflow-y: auto;
  scrollbar-width: thin;
  -ms-overflow-style: none;

  .carousel_wrapper {
    margin-top: 52px;
    // margin-left: 40px;
    padding-left: 40px;
  }

  .slick-dots-bottom {
    top: 65vh !important;
    padding-right: 40px;

    li {
      button {
        border-radius: 4px !important;
        opacity: 1;
      }
    }
  }
  .img_wrapper {
    position: relative;
    margin-bottom: 75px;

    img {
      height: 350px;
      object-fit: cover;
    }
    .rounded_arrow {
      // background-color: black;
      position: absolute;
      bottom: -82px;
      left: 0;
      width: 82px;
      height: 82px;
    }

    img {
      // object-fit: cover;
      right: 0;
      width: 100%;

      // max-height: 500px;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
  }

  h2 {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 16px;
  }
  p {
    color: #fff;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    max-width: 513px;
  }
}
.login_new {
  .form_bottom {
    font-size: 14px;
    font-weight: 400;
  }
}
.login_newform {
  label {
    color: #000 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    font-family: "Poppins" !important;
  }

  .ant-input-password {
    border-radius: 8px !important;
    border: 1px #d6d9e1 solid !important;
  }
  .ant-form-item {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.sign_up_new {
  .sub_heading {
    font-size: 18px;
    color: #000;
    font-weight: 400;
  }
  margin-bottom: 32px;
}

.locum_signup_new {
  .header {
    margin-bottom: 32px;
    .heading2 {
      margin-bottom: 10px;
    }
    .sub_heading {
      font-size: 18px;
      font-weight: 400;
      color: #000;
    }
  }
  .ant-form-item {
    margin-bottom: 14px;
  }
  .locum_signup_form_new {
    .ant-form-item-label {
      label {
        font-size: 12px;
        font-weight: 500;
        color: #000;
        font-family: "Poppins";
      }
      padding: 0 0 6px;
    }
    .ant-form-item-control-input {
      input::placeholder {
        color: #7b7b7b;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .ant-input {
      padding: 10px 14px !important;
      border-radius: 8px !important;
      border: 1px #d6d9e1 solid !important;
      color: #000;
      &::placeholder {
        color: #7b7b7b !important;
      }
    }

    .ant-input-password {
      padding: 10px 14px;
      .ant-input {
        padding: 0rem !important;
        border: none !important;
        border-radius: 0 !important;
      }
    }
    .ant-input-suffix {
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .ant-select-selection-placeholder {
      font-family: "Poppins";
      color: #7b7b7b;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .form_bottom {
    span {
      font-size: 14px;
      font-weight: 400;
    }
    a {
      margin-left: 6px;
      &:hover {
        color: #000 !important;
      }
    }
  }
}
