.modal-backdrop {
  &.show {
    opacity: 0.3 !important;
    z-index: 1050;
    &:has(.delete_document) {
      --bs-backdrop-opacity: 0.3 !important;
    }
  }
}
.modal-dialog-scrollable {
  height: 100vh;
}
.common_modal {
  .modal-dialog {
    max-width: 800px;
    padding-top: 60px;
    margin-top: 0;
    margin-bottom: 0;
    &:has(.export-gap-modal) {
      max-width: 566px;
    }
    &:has(.ant-step-wrap) {
      max-width: 800px;
      max-height: calc(100vh - 80px);
    }
    &:has(.branchListModal) {
      max-width: 589px;
    }

    &:has(.fileupload_modal) {
      max-width: 548px;
    }
    &:has(.delete_document) {
      max-width: 447px;
      padding-top: 40vh;
      .modal-body {
        padding: 20px 24px;
        padding-top: 0;
      }
      .modal-header {
        padding: 16px 24px;
        border-bottom: 1px solid transparent;
      }
      .modal-footer {
        border-top: 1px solid transparent;
      }
    }
    &:has(.add-q-modal) {
      max-width: 720px !important;
    }
  }
  &:has(.add-q-modal) {
    .modal-footer {
      padding: 16px;
      .add-shift-footer {
        width: 100%;
        justify-content: center !important;
        .ant-btn {
          border-radius: 24px !important;
          &:first-child {
            margin-right: 12px;
          }
          font-size: 14px !important;

          &.secondary-btn {
          }
          &.outlined {
            border-color: #475467;
            color: #475467 !important;
            &:hover {
              color: #fff !important;
            }
          }
        }
      }
    }
  }
  .modal-content {
    border: none;
    // margin-top: 80px;
  }
  .btn-close {
    color: #616161;

    height: 20px;
    width: 20px;
    padding: 4px;
  }
  .modal-header {
    padding: 16px;
    border-bottom: 1px solid #f5f5f5;
  }
  .modal_title {
    font-size: 16px;
    font-weight: 600;
    color: #171717;
  }
  .modal-body {
    padding: 24px 20px;
  }
  .modal-footer {
    border-top: 1px solid #e9e9e9;
    padding: 16px 24px;
    .primary-btn {
      border-radius: 48px;
      background: #02988e;
      padding: 9px 10.5px !important;
      min-width: 83px;
      width: auto;
      span {
        padding: 0 5px;
        font-size: 14px;
        font-weight: 500;
      }
      &:hover {
        background: #027a48;
      }
    }
    .secondary-btn {
      border-radius: 48px;
      padding: 9px 15.5px !important;
      border: 1px solid #d9d9d9;
      margin-right: 12px;
      min-width: 83px;
      width: auto;
      span {
        // padding: 0 5px;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        color: #262626;
      }
      &:hover {
        background: #02988e;
        span {
          color: #fff;
        }
      }
    }
  }
}
