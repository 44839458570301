.timesheet_table_container {
  .ant-table-body {
    overflow-x: auto !important;
    overflow: auto !important;
  }
  .ant-table-cell-scrollbar {
    border-right: 1px solid #f0f0f0;
    background: #f6f8f9 !important;
    box-shadow: 0 1px 0 1px #f6f8f9 !important;
  }
  .add-scroll {
    .ant-table-header {
      .ant-table-thead {
        .ant-table-cell-fix-right {
          right: 0px !important;
        }
        .ant-table-cell-scrollbar {
          display: none;
        }
      }
    }
  }
  table {
    font-family: "Poppins" !important;
    thead {
      th {
        background: #f6f8f9 !important;
        &:has(.weekend) {
          background: #fafafa !important;
        }
        p {
          font-size: 14px;
          font-weight: 500;
          color: #555555;
          b {
            color: #000;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
    tbody {
      tr {
        &:has(.table-footer) {
          td {
            background: #f6f8f9 !important;
            font-family: "Poppins";
            color: #101828;
            font-size: 14px;
            font-weight: 500;
          }
        }
        &.ant-table-measure-row {
          td {
            padding: 0 !important;
          }
        }
      }
      td {
        padding: 9px !important;
        vertical-align: middle;
        &:has(.empty_box) {
          background: #fafafa !important;
        }
        &:has(.leave_badge) {
          background: #fafafa !important;
        }
      }
    }
  }
}
.table-footer {
  p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
  }
  .badge_wrapper {
    margin-left: auto;
    .ant-badge {
      background: #f5f5f5;
      padding: 8px 8px;
      border-radius: 24px;
      span {
        font-family: "Poppins";
        font-size: 13px;
        font-weight: 500;
        color: #262626;
        margin: 4px 0;
        &:not(:last-child) {
          padding-right: 4px;

          //   position: relative;
          border-right: 1px solid #c4c4c4;
        }
      }
    }
  }
}
.ant-table-footer {
  .ant-select-selector {
    padding-left: 4px;
  }
  .ant-select-selection-item {
    font-family: "Inter" !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    color: #000 !important;
    margin-right: 6px;
  }
  .footer_left {
    form {
      label {
        font-family: "Inter" !important;
        font-size: 14px;
        font-weight: 400;
        color: #000;
      }
      border-right: 1px solid #e9e9e9;
      padding-right: 13px;
    }
    .items_toshow {
      padding-left: 24px;
      font-family: Inter;
      font-size: 14px;
      color: #9d9d9d;
      font-weight: 400;
    }
  }
  .footer-right {
    form {
      label {
        font-family: "Inter" !important;
        font-size: 14px;
        font-weight: 400;
        color: #9d9d9d;
      }
      // border-right: 1px solid #E9E9E9;
      padding-right: 32px;
    }
    .arrow-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        border: none;
        background: none;
        box-shadow: none;
        height: auto;
        width: auto;
        padding: 12px;
        border-radius: 0 !important;
        &:first-child {
          border-right: 1px solid #e9e9e9;
          border-left: 1px solid #e9e9e9;
        }
      }
    }
  }
}
.ant-tooltip {
  --antd-arrow-background-color: #101828;
  .ant-tooltip-inner {
    background-color: #101828 !important;
  }
}
// .select_field_wrapper {
//   > div {
//     @media (max-width: 1400px) {
//       margin-right: 8px !important;
//     }
//   }
// }
.search_field {
  @media (max-width: 1299px) {
    max-width: 100%;
  }
  @media (max-width: 959px) {
    max-width: 180px;
  }
}
// .attendence {
//   span {
//     @media (max-width: 1400px) {
//       font-size: 12px !important;
//     }
//   }
// }
// .select_field {
//   @media (max-width: 1480px) {
//     min-width: 160px !important;
//   }
// }
.employee_Name_td {
  // padding: 12px 16px 12px 12px;
  align-items: center;
  .emp_img {
    height: 32px;
    width: 32px;
    margin: 0;
    margin-right: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .title {
    h6 {
      margin-bottom: 0;
      a {
        font-size: 14px;
        font-weight: 400;
        color: #101828;
        text-decoration: none;
      }
      button {
        padding: 0;
        margin: 0;
        border: none;
      }
    }
    span {
      font-size: 13px;
      font-weight: 400;
      color: #667085;
    }

    .role {
      background-color: #e1f4f4;
      color: #07877c;
      border-radius: 5px;
      padding: 2px 8px;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .badge_wrapper {
    margin-left: auto;
    .ant-badge {
      background: #f5f5f5;
      padding: 8px 10px;
      border-radius: 24px;
      span {
        font-family: "Poppins";
        font-size: 13px;
        font-weight: 500;
        color: #262626;
        margin: 4px 0;
        &:not(:last-child) {
          padding-right: 4px;

          //   position: relative;
          border-right: 1px solid #c4c4c4;
        }
      }
    }
  }
}
.action_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    overflow: visible;
  }
  button {
    padding: 12px 12px !important;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;

    border: none !important;
  }
}

.ant-tooltip-inner {
  b {
    font-size: 12px;
    font-weight: 600;
    font-family: "Inter";
  }
  p {
    font-size: 12px;
    font-weight: 400;
    font-family: "Inter";
  }
}
.shifttime_wrapper {
  &.timesheet-badge {
    .ant-badge {
      span {
        font-family: "Poppins";
      }
    }
  }
  .ant-badge {
    font-family: "Inter";
    padding: 8.5px 12px !important;
    border-radius: 16px;
    display: flex;
    align-items: center;
    color: #344054;
    line-height: 1;

    i {
      margin-right: 4px;
      line-height: 0;
      margin-top: 1px;
    }
    span {
      font-family: "Inter";
      font-size: 14px !important;
      font-weight: 500 !important;
      text-wrap: nowrap;
      overflow: visible;
      line-height: 1;
    }
  }
  .pending_badge {
    background: rgba(2, 94, 143, 0.08);
    span {
      color: #025e8f;
    }
  }
  .approved {
    background: #ecfdf3;
    span {
      color: #027948;
    }
  }
  .incomplete_badge,
  .overtime_badge {
    background: #fff6eb;
    span {
      color: #fb8d00;
    }
  }
  .nocheckin_badge {
    background: rgba(211, 47, 47, 0.08);
    span {
      color: #d32f2f;
    }
  }
  .leave_badge {
    padding: 8.5px 0 !important;
    img {
      width: 12.7px;
    }
    span {
      color: #dd8d0d;
      font-size: 12px !important;
      // text-wrap: wrap;
    }
  }
}

.ant-popover-inner-content {
  ul {
    li {
      border-radius: 8px;
      padding-left: 12px;
      margin-left: -6px;
    }
  }
}
