.week-day-detail-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #ffffff;
  /* Colors/Black/Black 100 */
  padding-top: 5px;
  // overflow: hidden;
}

.week-day-detail-cell-no-shift {
  display: flex;
  width: -webkit-fill-available;
  height: 100%;
  margin: 10px !important;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
    background-image: url("../../../../assets/img/addButton.png") !important;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center center;
    height: calc(100% - 20px);
  }
}

.branch-detail-row-more-shift {
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  border-radius: 18px;
  padding: 8px;
  margin: 9px 10px;
  cursor: pointer;
  position: relative;

  &:hover {
    background: #f5f5f5;
  }
}

.branch-detail-row {
  width: 95%;
  display: flex;
  align-items: center;
  margin: 0px 5px;
  padding: 8px 5px 8px 13px;
  border-radius: 18px;
  cursor: pointer;
  position: relative;

  &:hover {
    background: #f5f5f5;
  }
}

.more-branch-detail-button {
  cursor: pointer;
  font-weight: bolder;
  padding: 10px 16px;
}

.week-day-detail-cell-closed {
  // background-image: url("../../../../assets/img/01.png");
  background-repeat: none;
  background-size: cover;

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }

  span {
    // width: 134px;
    white-space: normal;
    word-wrap: break-word;
    color: #7b7b7b;
    text-align: center;
  }
}

.leaves_table {
  vertical-align: middle;
  &:has(.leave_table_td) {
    .add_shift_box {
      display: none;
    }
  }
  &:hover {
    .add_shift_box {
      opacity: 1;
    }
  }
  &.month-schedular-slot-container {
    .add_shift_box {
      height: calc(100% - 10px);
    }
    .month_view_date{
      display: block;
      width: 100%;
      text-align: center;
  }
  }
  .add_shift_box {
    opacity: 0;
  }
}

.shift_details_week_view_td {
  display: table-cell;
  vertical-align: middle;
  // border-color: inherit;
  // border-style: solid;
  &.closed_day {
  }
  // to merge to table tds that are empty 
  // &:has(.empty_box) {
  //   border-right-width: 0px;
  //   border-left-width: 0px;
  // }
  &:has(.week-day-detail-cell-closed) {
    background-color: #fafafa !important;
  }
  &:has(.leave_table_td) {
    vertical-align: top;
    // border: 1px solid ;
    .add_shift_box {
      height: 100%;
    }
  }
  &:has(.week-day-detail-cell-closed) {
    vertical-align: middle;
  }
  .appear_on_hover {
    opacity: 0;
  }

  .add_shift_box {
    opacity: 0;
  }

  &:hover {
    .appear_on_hover {
      opacity: 1;
    }

    .add_shift_box {
      opacity: 1;
    }
  }
}

.branch-detail-row-badge {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
  background: #43a046;
}

.month-view-flex {
  padding: 8px;
  width: 134px;
}
