@import "../../../assets/scss/variables";

.sidebar {
    
    width: 20%;
    min-width: 20%;
    background: $white;
    border: 1px solid $black-100 !important;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    &.hidden {
        display: none;
    }

    &__top {
        //max-height: 330px;
        min-height: 200px;
    }

    &__bottom {
        flex-grow: 3;
    }

    &__link {
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $white;
        box-sizing: border-box;
        padding: 24px 35px;
        text-transform: capitalize;
        font-size: 15px;
        line-height: 20px;
        color: $black;
        text-decoration: none;

        i {
            &:before {
                color: $black;
            }
        }

        &:hover {
            color: $black;
            // background-color: $grey-100;
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &.active {
            background-color: $grey-100 !important;
            color: $black;

            i {
                &:before {
                    color: $black;
                }
            }
        }

        &.hidden {
            display: none;
        }

        &+.sidebar__link {
            border-top: none;
        }
    }
}