.create_profile_card {
  padding: 12px 16px;
  background-color: #e1f4f4;
  border-radius: 8px;
}
.card_header {
  font-family: "Poppins";
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  i {
    margin-right: 4px;
  }
  p {
    font-size: 14px;
    font-weight: 600;
    color: #1a202c;
  }
}
.card_body_text {
  font-size: 13px;
  font-weight: 400;
  font-family: "Poppins";
  color: #1a202c;
}

.progress_wrapper {
  padding: 8px 0;
}

.complete_profile {
  padding: 0;
  border: 0;
  box-shadow: none;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  span {
    color: #09988d !important;
    font-size: 14px;
    font-weight: 500;
    margin-right: 6px;
    border-bottom: 1px solid transparent;
    transition: all 0.3s ease-in-out;
    &:hover {
      border-bottom: 1px solid #09988d !important;
    }
  }
}

.ant-step-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  .ant-steps {
    width: 320px;
  }
  .ant-steps-item-icon {
    background-color: #f4f4f4 !important;
    border-radius: 50px;
    padding: 0 6px;
    .ant-steps-icon {
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 600;
    }
  }

  .ant-steps-item-active {
    .ant-steps-item-icon {
      background-color: #51c2bc !important;
      .ant-steps-icon {
        color: #fff !important;
      }
    }
  }
  .ant-steps-item-title {
    font-family: "Poppins";
    color: #262626;
    font-size: 14px;
    font-weight: 500;
  }
}
.complete_profile_modal {
  .modal-dialog {
    max-width: 800px;
    max-height: calc(100vh - 80px);
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-form-item-label {
      label {
        font-family: "Inter";
        font-weight: 400;
        font-size: 14px;
        color: #7b7b7b;
      }
    }
    .modal-footer {
      .ant-btn {
        min-width: 168px;
      }
    }
    .dragndrop {
      border: 1px solid #e4e7ec !important;
      background: #fff !important;
      flex-direction: row !important;
      padding: 16px 24px !important;
      justify-content: flex-start !important;
      margin-bottom: 32px;
      .icon_wrapper {
        margin-bottom: 0 !important;
      }
      .filetypes {
        padding-left: 20px;
      }
    }
  }
}

.ant-input {
  padding: 10px 16px !important;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.steptwoform {
  padding: 0 126px;
  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-form-item-label {
    label {
      color: #344054;
      font-size: 14px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
    }
  }
}
.contact_card {
  width: 96px;
  height: 96px;
  position: relative;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 50%;

  .edit_icon {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #51c2bc;
    padding: 3px 5px;
    border-radius: 6px;
  }
}
