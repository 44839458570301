@import "../../../assets/scss/typography";
@import "../../../assets/scss/variables";

.input-field {
  margin-left: 25px;
  width: 80%;
  height: 30px;
  box-sizing: border-box;
  background: #ffffff;
  border: none;
  /* Colors/Black/Black 200 */
}

.text-input-field {
  width: 100%;
  height: 40px !important;
  border: 1px solid $input-border-color;
  border-radius: 0.375rem;

  &:active {
    border: 1px solid $input-border-color;
  }

  &:focus {
    border: 1px solid $input-border-color;
  }

  &:hover {
    border: 1px solid $input-border-color;
  }

  &.picker {
    &.ant-picker-disabled {
      background-color: #fff;
    }
  }
  &.dayweek {
    #dateRange {
      padding: 6px 12px;
    }
  }
}

.input-field:focus {
  border: none;
  outline: none;
}

.search-field {
  min-width: 330px;
  height: 40px;
  box-sizing: border-box;
  padding-left: 16px;
  background: #ffffff;
  /* Colors/Black/Black 200 */

  border: 1px solid #d9d9d9;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.input-field-full {
  width: 100%;

  height: 30px;

  background: #ffffff;
  border: 1px solid #c8d4e2;
  box-sizing: border-box;
  border-radius: 5px;
}

.input-field-full:focus {
  border: 1px solid #c8d4e2;
  outline: none;
}

.error-field {
  border-color: $error-border-color;
  background-color: $error-50;
}

.error-helperText {
  color: $error-400;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.error-field {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
  box-shadow: none;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0px !important;
}

.input-group {
  position: relative;

  .location-search-input input {
    @extend .t1;
    border-radius: 8px !important;
    border: 1px solid #dee2e6 !important;

    .value {
      @extend .t1;
    }
  }

  .autocomplete-dropdown-container {
    position: absolute;
    width: 100%;
    top: 60px;
    z-index: 100;
    background: $white;

    .suggestion-item {
      @extend .t1;
      color: #262626;
      padding: 10px;
      background-color: $white;
      cursor: pointer;

      &--active {
        @extend .t1;
        color: #262626;
        padding: 10px;
        background-color: #f4f4f4;
        cursor: pointer;
      }
    }
  }
}

.textarea {
  width: 100% !important;
  min-height: 80px !important;
  background: $white !important;
  border: 1px solid #d9d9d9 !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  resize: none !important;
  padding: 5px 10px;

  &:focus-visible {
    outline: none;
  }

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  &.disabled {
    color: lightgray !important;
    border-color: lightgray !important;
  }
}
