.personal-info-wrapper {
  padding: 16px;

  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 16px;
  }

  .full_name {
    p {
      font-family: "Poppins", sans-serif;
      font-size: 14px;

      font-weight: 400;
    }
  }

  .profile_image {
    width: 20px;
    height: 20px;
    margin: 0;

    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info-list-wrap {
    &.hourly-rate {
      .text-row {
        .primary-text {
          width: 100%;
        }
      }
    }

    .text-row {
      //   width: 60%;
      padding: 0;
      justify-content: flex-start !important;
      margin-bottom: 16px;

      .primary-text {
        width: 20%;
      }

      .custom-tags {
        padding: 6px 16px;
        border: none;
        border-radius: 24px;
        background-color: #f5f5f5;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 400;

        &.green {
          padding: 6px 12px;
          background-color: #e0fffd;

          svg {
            margin-right: 4px;
          }
        }
      }
    }

    .text-row:nth-child(2n + 2) {
      background-color: #fff;
    }
  }

  .ant-collapse {
    background-color: #fff;
    .ant-collapse-header {
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding: 0;
      width: 20%;
      .ant-collapse-expand-icon {
      }
      .ant-collapse-header-text {
        margin-inline-end: 12px;
        flex: none;
      }
    }
    .ant-collapse-content-box {
      padding: 0;
      .pharma_options_wrapper {
        border-left: 1px solid #d9d9d9;
        width: auto;
        li {
          padding-left: 16px;
          &:hover {
            background: none;
          }
        }
      }
    }
  }
}

.info-modal-row {
  .ant-input {
    padding: 10px 14px !important;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    color: #000;
  }

  .ant-form-item-row {
    flex-direction: column;
    justify-content: flex-start;
  }

  .select-field {
    &:hover {
      .ant-select-selector {
        border-color: #d9d9d9 !important;
      }
    }

    .ant-select-selector {
      padding: 4px 14px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;

      height: auto;

      .ant-select-selection-item {
        color: #000;
      }

      input {
        // padding: 10px 14px;
        &::placeholder {
          font-family: "Poppins", sans-serif !important;
        }
        height: auto;
      }
    }
  }

  .ant-form-item-label {
    text-align: left;

    label {
      color: #000 !important;
      font-size: 14px;
      font-family: "Poppins", sans-serif !important;
      font-weight: 500 !important;

      &::after {
        display: none;
      }
    }
  }

  .date-picker {
    width: 100%;
    padding: 7px 14px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;

    .ant-picker-input {
      input {
        &::placeholder {
          font-family: "Poppins", sans-serif !important;
        }
      }
    }
  }

  .ant-select-selection-item {
    border: none !important;
    border-radius: 6px !important;
    padding: 4px 6px !important;
    height: auto !important;
    line-height: normal !important;
    align-items: center !important;

    .ant-select-selection-item-content {
      display: flex;

      span {
        font-family: "Poppins", sans-serif;
      }
    }

    .pharma-img-wrap {
      width: 24px;
      height: 24px;
      margin: 0;

      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .ant-space-item {
      p {
        margin-bottom: 0;
        font-family: "Poppins", sans-serif !important;
      }
    }
  }
}

.checkbox {
  margin-top: 16px;
}

.pharma-img-wrap {
  width: 24px;
  height: 24px;
  margin: 0;

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.propover-content {
  .ant-space-item {
    width: 100%;
  }

  .ant-checkbox-inner {
    border-radius: 6px;
  }

  .ant-input-affix-wrapper {
    border-radius: 8px !important;
    height: 34px;

    .ant-input-prefix {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .ant-input {
    padding-left: 2px !important;
  }
}

.dropdown-render {
  width: 100%;

  .dropdown_item {
    cursor: pointer;
    padding: 2px 6px;
    display: flex;
    justify-content: space-between;
    transition: all 0.3s ease-in;
    border-radius: 4px;

    button {
      &:hover {
        background: none;
      }
    }

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .clear_all_btn {
    height: auto;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    margin-left: auto;

    span {
      color: #09988d;
    }
  }
}

.ant-space-item {
  p {
    margin-bottom: 0;
    font-family: "Poppins", sans-serif !important;
  }
}
