@import '../../../assets/scss/variables';

.global-loader {
    &.overlay {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.75);
        z-index: 5000;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s ease;
        overflow: hidden;

        &.isActive {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &__loader {
        background-color: transparent !important;

        &.blue {
            .ui.loader {
                color: $black !important;

                &:before {
                    border-color: rgba(0, 0, 0, 0.15) !important;
                }

                &:after {
                    border-color: $locum-blue transparent transparent !important;
                }
            }
        }
    }
}



.load {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*change these sizes to fit into your project*/
    width: 100px;
    height: 100px;
}

.load hr {
    border: 0;
    margin: 0;
    width: 40%;
    height: 40%;
    position: absolute;
    border-radius: 50%;
    animation: spin 2s ease infinite;
    opacity: 1 !important;
}

.load :first-child {
    background: #C4d560;
    animation-delay: -1.5s
}

.load :nth-child(2) {
    background: #eaada5;
    animation-delay: -1s
}

.load :nth-child(3) {
    background: #85d3d0;
    animation-delay: -0.5s
}

.load :last-child {
    background: #f4c45e
}

@keyframes spin {

    0%,
    100% {
        transform: translate(0)
    }

    25% {
        transform: translate(160%)
    }

    50% {
        transform: translate(160%, 160%)
    }

    75% {
        transform: translate(0, 160%)
    }
}