.dragndrop_clickToUpload {
  border: 1px solid #e4e7ec !important;
  background: #fff !important;
  flex-direction: row !important;
  padding: 16px 24px !important;
  justify-content: flex-start !important;
  margin-bottom: 32px;
  display: flex;
  .icon_wrapper {
    margin-bottom: 0 !important;
  }
  

  &.upload_box {
    border-radius: 8px;
    border: 1px solid #e4e7ec;
    background: #fff;
    padding: 15.5px 24px;
    margin-bottom: 0;
    flex-direction: row;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'poppins', sans-serif;
    &:has(.completed) {
      border: 1px solid #51c2bc;
      .cross {
        align-self: center;
      }
    }

    &:has(.failed) {
      border: 1px solid #f28e89;
      background-color: #ffebeb;
      padding: 16px;
      .filename {
        color: #c8352f;
      }
      .failed {
        color: #ec5d56;
      }
    }
    figure {
      margin-bottom: 0;
    }
    .file_input_area {
      // padding: 0 12px 4px;
      color: #667085;
      margin-bottom: 6px;
      span {
        cursor: pointer;
        text-decoration: underline;
        color: #09988d;
        font-weight: 500;
      }
    }
    p {
      // padding-left: 12px;
    }
    .filetypes {
      color: #667085;
      text-align: left;
    }
    span {
      padding-right: 4px;
    }
  }
}
