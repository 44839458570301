.errorScreen {
  width: 100%;
  height: calc(100vh - 60px);
  gap: 56px;
  background-image: url("../../assets/img/errorBg.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 50% 25%;
 
  .errorText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 244px;
    line-height: 180px;
    /* identical to box height, or 74% */

    text-align: center;

    /* Colors/Primary/Primary 800 */

    color: #07776c;
  }
}
