@import "./assets/scss/main";
@import "~reset-css";
@import "~react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
@import "~rc-time-picker/assets/index.css";

* {
  box-sizing: border-box;
}

html {
  background: $primaryBgColor;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #2a3e4a;
}

body {
  font-family: "Open Sans", "Roboto",
    -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background: $primaryBgColor;
}

body,
html {
  height: auto;
  min-height: 100vh;
  // overflow: auto;
}

a {
  text-decoration: none;
}

.notification-container-bottom-right {
  min-width: 200px;
}

.main-container {
  margin: 0 auto;
  max-width: 1320px;
}

.main-wrapper {
  width: 100%;

  .back-button-holder {
    margin: 0 auto;
    width: 100%;
    max-width: 1320px;

    @media (max-width: 1445px) {
      padding: 0 60px;
      margin: 0;
    }
  }

  .content-holder {
    display: flex;
    // justify-content: center;
    position: relative;
    gap: 40px;
    padding: 25px 60px 60px 60px;

    &.locumview {
      .sidebarnew {
        margin-top: -45px;
      }
    }
  }
}

// schedule calendar popup
.rbc-overlay {
  .rbc-overlay-header {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $lighten-black;
  }

  .rbc-event {
    background: $locum-green;
    border-radius: 2px;
    padding: 8px;

    .rbc-event-content {
      font-size: 14px;
      line-height: 17px;
      text-align: center;
    }
  }
}

.scrolling.dimmable > .dimmer {
  justify-content: center;
}

.calendar-page {
  height: 100%;
  position: relative;

  &__switcher {
    position: absolute;
    right: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

//
.table-default-logo {
  // background-image: url("../../assets/icons/default-pharmacy.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #eff3f9;
  width: 25px;
  height: 25px;
}

.new-custom-button {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #51c2bc;
  color: #fff;
  font-weight: 500;
  border-radius: 24px;

  &.bordered {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    color: #262626;
  }
}

.activeLi {
  background-color: #e1f4f4;
}

.z-index-fix {
  z-index: 2 !important;
}

input[type="radio"]:disabled {
  cursor: not-allowed;
}

input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.no-branch-btn {
  &.custom_btn {
    border-radius: 8px !important;
  }
}

.ConfirmationZindex {
  z-index: 1400 !important;
}
.modal-content {
  border: none !important;
}
.wrapped-notes {
  word-wrap: break-word;
}

.modal-primary-button {
  border-radius: 8px !important;
  background: #02988e;
  padding: 9px 10.5px !important;
  min-width: 83px;
  width: auto;
  color: white;
}

.modal-secondary-button {
  border-radius: 8px !important;
  background: #02988e;
  padding: 9px 10.5px !important;
  min-width: 83px;
  width: auto;
  color: white;
}
