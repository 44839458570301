@import "../../../../assets/scss/variables";

.details-page {
  .content-holder {
    display: flex;
    gap: 40px;

  }

  .content-container {
    // height: 690px;

    // border-color: $black-100 !important;
  }

  .branch-info-page {
    height: 100%;

    .custom-tabs{
      .ant-tabs-content-holder{
        margin: 16px;
      }
    }
  }

  .inner-content {
    height: calc(690px - 55px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}