@import "../../../assets/scss/variables";

.sidebarnew {
  margin-top: -25px;
  margin-left: -60px;
  width: 20%;
  min-width: 20%;
  background: $white;
  border: 1px solid $black-100 !important;
  border-top: 0 !important;
  box-sizing: border-box;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  // max-height: calc(100vh - 120px);

  // overflow: auto;
  position: sticky;
  top: 0;

  &.hidden {
    display: none;
  }

  &__footer {
    border-top: 1px solid #e4e7ec;
    padding: 24px 0px 32px 0px;
    margin: 0 16px;
  }
  &__top {
    //max-height: 330px;
    min-height: 200px;
    margin-bottom: 32px;
  }

  &__bottom {
    flex-grow: 3;
    padding: 0 16px;
    flex: 1;
    overflow: auto;
  }

  &__link {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: $white;
    box-sizing: border-box;
    padding: 12px;
    border-radius: 6px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: $black;
    text-decoration: none;
    color: #434343;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    i {
      margin-right: 12px;
      &:before {
        color: $black;
      }
    }

    &:hover {
      color: $black;
      // background-color: $grey-100;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.active {
      background-color: #e1f4f4 !important;
      color: #09988d;

      i {
        svg {
          path {
            fill: #09988d;
          }
        }
        &:before {
          color: $black;
        }
      }
    }

    &.hidden {
      display: none;
    }

    & + .sidebar__link {
      border-top: none;
    }
  }
}
