.date-filter-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;

  min-width: 260px;
  height: 44px;

  background: #ffffff;
  border: 1px solid #e1e5e8;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}
.date-filter-button {
  height: auto !important;

  border-radius: 24px !important;
  border: 1px solid #e1e5e8;
  background: #fff;
  box-shadow: none;
  padding: 8px 12px;
  &:hover {
    text-decoration: none;
    box-shadow: none;
  }
  .ant-space {
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: none;
      box-shadow: none;
    }
    .ant-space-item{
      &:hover {
        text-decoration: none;
        box-shadow: none;
      }
      p{
        &:hover {
          text-decoration: none;
          box-shadow: none;
        }
      }
    }
  }
}

.last-updated {
  color: #7b7b7b !important;
  font-size: 12px;
  margin-top: 8px;
}
