@import "./mixin";


//==========headings ============//
.heading1 {
    @include typography(Poppins, 600, 40px, 60px, 0px, #171923);
}

.heading2 {
    @include typography(Poppins, 600, 32px, 48px, 0px, #171923);
}

.heading3 {
    @include typography(Poppins, 600, 24px, 36px, 0px, #171923);
}

.heading4 {
    @include typography(Poppins, 600, 20px, 30px, 0px, #171923);
}

.heading5 {
    @include typography(Poppins, 600, 16px, 24px, 0px, #171923);
}

.heading6 {
    @include typography(Poppins, 600, 12px, 18px, 0px, #171923);
}

//========body=============//
.p1 {
    @include typography(Poppins, 400, 18px, 28px, 0px, #171923);
}

.p2 {
    @include typography(Poppins, 400, 16px, 24px, 0px, #171923);
}

.p3 {
    @include typography(Poppins, 400, 14px, 20px, 0px, #171923);
}
.p4{
    @include typography(Poppins, 400, 10px, 16px, 0px, #434343);
}

.button {
    @include typography(Poppins, 500, 16px, 24px, 0px, #ffffff);
}
.b1 {
    @include typography(Poppins, 500, 16px, 24px, 0px, #171923);
}

.b2 {
    @include typography(Poppins, 500, 14px, 20px, 0px, #171923);
}
.b3{
    @include typography(Poppins, 500, 16px, 20px, 0px, #171923); 
}

.c1 {
    @include typography(Poppins, 600, 14px, 20px, 0px, #171923);
}

.c2 {
    @include typography(Poppins, 500, 12px, 18px, 0px, #171923);
}

.s1 {
    @include typography(Poppins, 500, 14px, 20px, 0px, #171923);
}

.s2 {
    @include typography(Poppins, 500, 12px, 18px, 0px, #171923);
}

.t1 {
    @include typography(Poppins, 400, 14px, 20px, 0px, #555555);
}

.t2 {
    @include typography(Poppins, 400, 12px, 18px, 0px, #171923);
}

.role-text{
    @include typography(Poppins, 500, 12px, 16px, 0px, #171923);
}
.round-icon {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background: #f44336;
    margin-right: 8px;
}
.ui.icon.input{
    padding-right: 0px !important;
}