@import "../../../../../assets//scss/variables";

.primary-text {
  color: $black-500 !important;
  width: 100%;
  text-transform: capitalize;
}

.secondary-text {
  color: $black !important;
  width: 100%;
}

.text-row {
  padding: 20px;
}

.text-row:nth-child(2n + 2) {
  background-color: $black-50;
}

.day-box {
  background:none;
  
  width: 100%;
  gap: 15px;
}

.ant-modal .ant-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px !important;
}