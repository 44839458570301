body {
  background-color: #f9fafb !important;
  font-family: "Poppins" !important;
}

.toggle_btn {
  span {
    &:hover {
      background-color: #027a48;
    }
  }
}
.schedule_new {
  .heading4 {
    color: #1d2939;
  }
}
.icon_wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e1f4f4;
  border-radius: 50%;
  margin: 0;
  margin-bottom: 0;
  margin-right: 10px;

  img {
    width: 24px;
    height: 24px;
  }
}
.select_field {
  .ant-select-selector {
    .ant-select-selection-item {
      font-size: 16px;
      font-weight: 400;
      color: #101828;
    }
  }
  padding: 7.11px;
  height: auto;
}
.date_selector {
  padding: 4.95px;
}
.search_field {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  padding: 14px;
  @media (max-width: 1299px) {
    max-width: 200px;
  }
}
.leave_table_box {
  // &:last-child {
  //   margin-bottom: 75px !important;
  // }
  &.leave_table_page {
    .weekview_table {
      tbody {
        th {
          text-align: left;
          height: 70px;
          min-width: 177px;
        }
      }
      td {
        height: 70px;
        min-width: 177px;
      }
    }
  }
  &.rounded-top {
    border-top-right-radius: 16px !important;
    border-top-left-radius: 16px !important;
  }
  &.rounded-bottom {
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
  .rounded-top-inner {
    border-top-right-radius: 16px !important;
    border-top-left-radius: 16px !important;
  }
  .rounded-bottom_inner {
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
  table {
    margin-bottom: 0 !important;
  }
  h5 {
    font-size: 16px;
    color: #000;
    font-family: "Poppins";
  }
}
.leaves {
  .leaves_header {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .leave_count {
    p {
      color: #7b7b7b;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0;
      overflow: visible;

      &:not(:last-child) {
        margin-right: 6px;
      }
      &:last-child {
        margin-left: 6px;
      }
      span {
        color: #000;
      }
    }
    .separator {
      color: #d9d9d9;
    }
  }
  .dropdown_btn {
    background: none;
    padding: 0;
    margin: 0;
    border: none;
  }
}

.dropdown {
  ul {
    display: block;
    list-style: none !important;
  }
}
.selected_date {
  img {
    margin-right: 6px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #101828;
  }
}
.add_shift {
  font-size: 14px !important;
  font-weight: 500 !important;
}
.table_container {
  overflow-x: auto;
}
.template_preview_modal {
  .weekview_table {
    border-radius: 10px;
    .week-day-closed {
      > div {
        height: 90%;
        display: flex;
        flex-direction: column;
      }
    }
    .week-day-detail-cell-closed {
      margin: auto;
    }
  }
}
.ant-select-item {
  border-radius: 8px !important;
  margin-bottom: 6px;
}
.dropdown-menu {
  li {
    margin: 0 6px;
    button {
      border-radius: 8px !important;
    }
  }
}
.weekview_table {
  &.dayview_table {
    margin-bottom: 0;
    th {
      min-width: 200px;
      .dates {
        font-size: 14px;
        font-weight: 400;
        font-family: "Poppins";
        text-align: center;
      }
    }
    thead {
      th {
        vertical-align: middle;
      }
    }
    tbody {
      th {
        height: 80px;
      }
    }
    .schedular-slot-container {
      height: 80px;
      width: 175px;
    }
  }
  &.monthview_table {
    th {
      background-color: #fcfeff;
      .s1 {
        color: #555555;
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 400;
      }
    }
    td {
      height: 138px;
    }
  }
  thead {
    .roles {
      button {
        font-size: 14px;
        font-weight: 400;
        color: #000;
        display: flex;
        align-items: center;
        font-family: "Poppins";
        padding: 6px 12px;
        img {
          margin-right: 5px;
        }
      }
    }
    .dates {
      font-size: 14px;
      font-weight: 400;
      font-family: "Poppins";
      text-transform: capitalize;
      text-align: center;
    }
    th {
      border-left-width: 0;
    }
  }
  th,
  td {
    &.current_date {
      background-color: #f4ffff;
    }
  }
  tbody {
    th {
      text-align: center;
      min-width: 175px;
      min-height: 116px;
      height: 100%;
      vertical-align: middle;
      border-left-width: 0;
    }
  }
  th {
    text-align: left;
    &.week_array {
      min-width: 151px !important;
      padding: 2px 12px;
      vertical-align: middle;
      p {
        color: #555555;
        b {
          font-weight: 400;
          color: #000;
        }
      }
      &.current_day {
        background-color: #f4ffff;
        border-bottom: 4px solid #0ba69b !important;
      }
    }
    &.vertical_day_th {
      padding: 12px 24px !important;
      font-size: 16px;
      font-weight: 500;
    }
    .date_th {
      padding: 12px;
      color: #555555 !important;
      font-size: 18px !important;
      font-weight: 600 !important;
      font-family: "Poppins";
      b {
        color: #000;
      }
    }
  }
  @-moz-document url-prefix() {
    td {
      max-height: 116px !important;
      height: auto !important;
    }
  }
  td {
    padding: 12px !important;
    min-width: 195px;

    // position: relative;
    height: 116px;

    // text-align: center;
    &.month-schedular-slot-container {
      .month_view_date {
        display: block;
        width: 100%;
        text-align: center;
        font-family: "Poppins";
        font-weight: 500;
        font-size: 14px;
        color: #101828;
        line-height: 1.3;
        margin-bottom: 8px;
      }
    }
    &.current_day {
      background-color: #f4ffff;
    }
    &.week-day-closed {
      > div {
        height: 70%;
      }
      background-color: #fafafa;

      &:hover {
        // background: #e4e7ec;
        .week-day-detail-cell-closed {
          display: flex !important;
          background: none !important;
          align-items: center;
        }
      }
      .week-day-detail-cell-closed {
        display: none !important;
      }
    }
  }
  .popover_btn {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
  .item_type {
    padding: 12px;
    font-size: 16px;
    font-weight: 500;
  }

  .leave_table_td {
    width: 100%;
    padding: 8px 8px;
    background-color: #d8f4f1;
    border-radius: 12px;
    margin-bottom: 10px;
    border-width: 1px !important;
    .ant-popover-inner {
      padding: 0 !important;
      box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.08),
        0 4px 8px -4px rgba(0, 0, 0, 0.12),
        0 11px 30px 8px rgba(0, 0, 0, 0.05) !important;
    }
    &.leave_table {
      width: auto;
    }
    &.monthview {
      width: 165px;
    }
    &.dayView {
      position: absolute;
      z-index: 1;
    }
    &.sick_leave {
      background-color: #f9dfde;
    }
    &.locum {
      background-color: #cfe5fc;
      border: 2px solid #3578bf;
    }
    &.pending_review {
      background-color: #fdeec7;
      border: 2px dashed #000;
    }
    .review {
      align-items: center;
      span {
        margin-left: auto;
        border-radius: 25px;
        background-color: #000;
        padding: 4px 10px;
        color: #fff;
      }
    }
    &.no_name {
      background-color: #fff;
      border: 2px dashed #9d9d9d;
    }
    h6 {
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      color: #101828;
    }
    p {
      margin-bottom: 0;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

.add_shift_box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 24px;
  // height: 30px;
  padding: 10px;
  margin: auto 0;
  cursor: pointer;
  height: 100%;
}

.dropdown-menu {
  border: none !important;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.4);
  border-radius: 8px !important;
}
.roles {
  .dropdown-menu {
    display: block !important;
    list-style: none !important;
  }
}
.pharma_time_sheet {
  .status_badge {
    background-color: #ecfdf3;
    color: #027a48 !important;
    line-height: inherit;
    padding: 4px 12px;
    font-size: 14px;
    font-weight: 500;
    font-family: "Inter";

    &.close {
      background-color: #fef3f2;
      color: #b42318 !important;
    }
  }

  .pharma_name {
    > div {
      margin-bottom: 4px;
    }
    h6 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0 !important;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #555555;
    }
  }
}

.rgt_side {
  margin-bottom: 0;
  .filter_btn {
    position: relative;
    &::after {
      position: absolute;
      content: "";
      top: 0;
      right: 1px;
      width: 6px;
      height: 6px;
      background-color: #f67280;
      border-radius: 50px;
    }
  }

  &.schedule_view {
    li {
      &:first-child {
        border: none;
      }
      &:nth-child(2) {
        border-left: 1px solid #d9d9d9;
      }
    }
  }
  list-style: none !important;
  padding-left: 0;
  li {
    &:not(:last-child) {
      border-right: 1px solid #d9d9d9;
      padding: 0 12px;
    }
    &:last-child {
      padding-left: 12px;
      padding-right: 0;
    }
  }
  .ant-dropdown {
    ul {
      li {
        border: none;
      }
    }
  }
  button {
    padding: 0;
    position: relative;
    // margin-left: 12px;
    border-width: 0px !important;
    &:not(:last-child) {
      // padding-right: 12px;
      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 23px;
        background-color: #d9d9d9;
      }
    }
    &:active {
      border: none;
      border-color: transparent;
    }
    &:focus {
      border: none;
      border-color: transparent;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.custom_btn {
  border-radius: 50px !important;
  background: #51c2bc !important;
  padding: 8px 16px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #fff !important;
  transition: all 0.4s ease-out !important;
  display: flex !important;
  align-items: center;
  img {
    margin-right: 4px;
  }
  &:disabled {
    background-color: #c4c4c4 !important;
    cursor: not-allowed !important;
    &:hover {
      background-color: #c4c4c4 !important;
    }
  }
  &:hover {
    background: #027a48 !important;
  }
  &.auth_button {
    background-color: #02988e !important;
    min-width: 187px;
    width: 100%;
    height: auto !important;
    padding: 11px 16px !important;
    &:disabled {
      background-color: #c4c4c4 !important;
      &:hover {
        background-color: #c4c4c4 !important;
      }
    }
    span {
      font-weight: 500;
    }
  }
  &.assign-btn {
    justify-content: center;
    span {
      color: #fff;
    }
  }

  &.outlined {
    img {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }
    background: #fff !important;
    border: 1px solid #09988d;
    color: #02988e !important;
    padding: 8.5px 13px !important;
    span {
      font-size: 14px;
      font-weight: 500;
    }
    &:hover {
      background: #09988d !important;
      color: #fff !important;
      img {
        filter: brightness(0) invert(1) !important;
      }
    }
  }
  &.attendence {
    background: #02988e !important;
    padding: 11.5px 10.3px !important;
    height: 100%;
    white-space: nowrap;

    &:hover {
      background: #027a48 !important;
    }
    span {
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.weeks {
  background-color: #f3f8fa;
  padding: 12px 24px;
  button {
    padding: 8px 16px;
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}
.shift_time {
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-right: 6px;
}
.breakTimeSpan {
  padding: 2px 6px;
  background-color: #e2fffc;
  border-radius: 15px;
  img {
    margin-right: 4px;
  }
}

.empty_td {
  min-height: 44px;
}

.shift_popup {
  .popup_header {
    // width: 500px;
    margin: 0 -10px;
    padding: 6px 12px !important;

    .bg-success {
      background: #e1f4f4 !important;
      color: #07877c !important;
      padding: 6px 12px;
      font-size: 12px;
      font-weight: 500;
      font-family: "Poppins";
    }

    .popup_buttons {
      margin-left: auto;
      button {
        img {
          height: 18px;
          width: 18px;
        }
        &:last-child {
          img {
            height: 20px;
            width: 20px;
          }
        }
        border-width: 0px !important;
        position: relative;
        &:active {
          border: none;
          border-color: transparent;
        }
        &:focus {
          border: none;
          border-color: transparent;
        }
        &:last-child {
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 23px;
            background-color: #d9d9d9;
          }
        }
      }
    }
  }
  .popup_body {
    &:has(.leave_detailspopup_body_content) {
      max-width: 468px;
    }
    padding: 16px 8px;
    .body_top {
      &:has(.leave_detailspopup_body_content) {
        margin-bottom: 0;
        ul {
          margin-bottom: 0;
        }
      }
      display: flex;
      justify-content: flex-start;
      margin-bottom: 16px;
      .date_view {
        display: flex;
        flex-direction: column;
        margin-right: 12px;
        span {
          font-family: "Inter";
          text-align: center;
          &:first-child {
            padding: 2px 12px;
            border-top-right-radius: 12px;
            border-top-left-radius: 12px;
            background-color: #eb3c4d;
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            border: 1px solid #d9d9d9;
            border-bottom: none;
          }
          &:last-child {
            padding: 2px 12px;
            border-bottom-right-radius: 12px;
            border-bottom-left-radius: 12px;
            background-color: #fff;
            color: #000;
            font-size: 14px;
            font-weight: 500;
            border: 1px solid #d9d9d9;
            border-top: none;
          }
        }
      }
      .branch_name {
        h5 {
          font-size: 16px;
          font-weight: 600;
        }
        .bg-info {
          border-radius: 50px;
          padding: 4px 12px 4px 6px;
          background: #f0f9ff !important;
          color: #026aa2 !important;
          font-size: 14px;
          font-weight: 500;
          font-family: "Poppins";
        }
        .ant-avatar {
          width: 20px;
          height: 20px;
          line-height: normal;
        }
      }
      .pattern {
        display: flex;
        align-items: start;
        margin-left: auto;
        p {
          font-size: 14px;
          font-weight: 500;
          font-family: "Poppins";
        }
      }

      .leave_detailspopup_body_content {
        ul {
          margin-left: 0;
          padding-left: 0;
          list-style-type: none !important;
          li {
            font-family: "Poppins";
            color: #000;
            font-weight: 500;
            display: flex;
            align-items: center;
            &:last-child {
              align-items: flex-start;
            }
            &:not(:last-child) {
              margin-bottom: 16px;
            }
            .duration {
              width: auto;
              margin-right: 4px;
              color: #000;
              font-weight: 500;
            }
            p {
              margin-bottom: 0;
              width: 120px;
              color: #555555;
              font-size: 14px;
              font-weight: 400;
            }

            .li_right_side {
              width: calc(468px - 120px);
              margin-left: 16px;
              &:last-child {
                width: calc(468px - 150px);
              }
            }
            .badge {
              border-radius: 16px;
              padding: 4px 12px 4px 10px;
              font-size: 14px;
              font-weight: 500;
              .badgeImage {
                img {
                  width: 16px;
                  height: 16px;
                  margin-right: 6px;
                  border-radius: 50px;
                }
              }

              &.bg-secondary {
                background: #f5f5f5 !important;
                color: #000;
              }
              &.bg-info {
                background: #f0f9ff !important;
                color: #026aa2;
                padding: 4px 12px 4px 6px;
              }
            }
          }
        }
      }
    }
    .break_time {
      padding-top: 18px;
      span {
        padding: 4px 12px;
        font-size: 12px;
        font-weight: 400;
        color: #434343;
        font-family: "Poppins";
      }
    }

    .shift_timing_wrapper {
      list-style-type: none !important;
      li {
        position: relative;
        // ::before {
        //   content: "";
        //   position: absolute;
        //   height: 16px;
        //   width: 16px;
        //   left: -25px;
        //   top: 50%;
        //   transform: translateY(-50%);
        //   border-radius: 50px;
        //   background-color: #fff;
        //   border: 1px solid #d9d9d9;
        //   z-index: 2;
        // }
        // &:not(:last-child) {
        //   padding-bottom: 18px;
        //   ::after {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: -18px;
        //     height: 100%;
        //     width: 0px;
        //     border-left: 2px dashed #d9d9d9;
        //     z-index: 1;
        //   }
        // }
      }
    }
    .shift_times {
      .shift_no {
        font-size: 14px;
        font-weight: 400;
        color: #555555;
        font-family: "Poppins";
        margin-bottom: 4px;
      }
      .duration {
        p {
          font-size: 20px;
          font-weight: 600;
          span {
            color: #555555;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
.ant-tooltip-inner {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 600;
}

.add_shift_form {
  .not-required {
    label {
      &::before {
        display: none !important;
      }
    }
  }
  &.publish_modal {
    .text-input-field {
      height: auto !important;
      padding: 12px 16px !important;
      border-radius: 8px;
    }
  }
  .ant-select {
    border: 1px solid #d6d9e1;
  }
  .ant-radio-group {
    .ant-radio-checked {
      .ant-radio-inner {
        border-color: #09988d !important;
        background-color: #e1f4f4 !important;
        &::after {
          background-color: #09988d !important;
        }
      }
    }

    .ant-radio-wrapper {
      span {
        &.ant-radio + * {
        }
      }
    }
  }
  .ant-form-item-label {
    label {
      font-size: 12px;
      font-weight: 500;
      color: #000;
      font-family: "Poppins";
    }
  }
  .text-input-field {
    height: auto !important;
    padding: 6px 4px !important;
    border-radius: 8px;
  }
  .assign_locum {
    .ant-form-item {
      &:not(:last-child) {
        padding: 6px 0;
      }
    }
    .text-input-field {
      height: auto !important;
      padding: 12px 16px !important;
      border-radius: 8px;
      &::placeholder {
        color: #7b7b7b;
      }
    }
  }
  .selected_week {
    font-family: "Poppins";

    color: #344054;
  }
  .ant-select-selection-item {
    color: #101828 !important;
  }
  .ant-checkbox-wrapper {
    color: #000;
  }
  .ant-form-item-control-input {
    min-height: auto;
  }
  .ant-picker-range {
    // padding: 14px 14px !important;

    border: none;
    box-shadow: none;
    padding: 0 !important;

    .ant-picker-input {
      border-radius: 8px;
      padding: 12px 14px;
      border: 1px #d6d9e1 solid;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        height: 24px;
        width: 24px;
        background-image: url("../../../assets/icons/calendar.svg");
        background-repeat: no-repeat;
      }
      &:nth-child(3) {
        &::before {
          position: absolute;
          content: "End Date";
          left: 0;
          top: -27px;
          height: 24px;
          width: 60px;
          text-wrap: nowrap;
          font-size: 12px;
          font-weight: 500;
          color: #000;
          font-family: "Poppins";
          line-height: 18px;
        }
      }
    }
    .ant-picker-active-bar {
      width: 196px !important;
    }
  }
  .add_shift_selected {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #f7fafc;
    border-radius: 8px;
    padding: 12px 14px;
    border: 1px #ccd5df solid;
    .ant-form-item-row {
      width: 100%;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      margin-left: 10px;
    }
    .assign_locum {
      margin-top: 16px;
      .total {
        background-color: #e6f1fc;
        border-radius: 8px;
        padding: 14px 14px;
        text-wrap: nowrap;
        margin-left: 8px;

        p {
          margin-left: 0;
          font-size: 14px;
          font-weight: 400;
          font-family: "Poppins";
        }
      }
      .ant-form-item {
        &:not(:last-child) {
          padding: 0;
        }
      }
    }

    .selected_shift_icons {
      display: flex;
      span {
        &:not(:last-child) {
          border-right: 1px solid #d9d9d9;
        }
        &:last-child {
          button {
            padding-right: 0;
          }
        }
        button {
          background: none;
          height: auto;
          border-radius: 0 !important ;
          border: none;
          box-shadow: none;
          padding: 0 12px;
          img {
            width: 20px;
            height: 20px;
          }

          &.clipicon {
            &:hover {
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }
  // body {
  //   .create-client-modal:where(
  //       .css-dev-only-do-not-override-1hlmw7u
  //     ).ant-radio-wrapper {
  //     font-family: "Poppins" !important;
  //     .ant-radio {
  //       + * {
  //         font-family: "Poppins" !important;
  //       }
  //     }
  //   }
  // }

  // body :where(.shift_radio_group).ant-radio-wrapper span.ant-radio+* {
  //   /* Your overriding styles here */
  //   color: blue; /* Change text color to blue */
  //   font-weight: bold; /* Add more styles as needed */
  // }

  .create-client-modal {
    .modal-dialog {
      background-color: red;
      .modal-content {
        .modal-body {
          .shift_job_radio_group {
            .ant-radio-group {
              &.shift_radio_group {
                .ant-radio-wrapper {
                  background-color: red;
                  span {
                    font-family: "Poppins" !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      border: 1px solid #09988d36 !important;
    }
  }

  table {
    &.ant-picker-content {
      thead {
        th {
          color: #7b7b7b !important;
        }
      }
    }
  }
}

.confirmModal {
  &:has(.performance-review-modal) {
    .modal-dialog {
      max-width: 440px !important;
    }

    .confirm_modal {
      padding: 16px !important;
      gap: 0 !important;
    }
    .main_modal_body {
      margin: 0 -15px !important;
      padding: 20px !important;
    }
    .modal_bottom {
      justify-content: center !important;

      padding: 16px 0px;
      .cancel_btn {
        border-radius: 25px;
        width: auto;
        min-width: auto;
        border-color: #475467 !important;
        span {
          color: #475467 !important;
        }

        &:hover {
          background: #fff !important;
          border-color: #d72518 !important;
          span {
            color: #d72518 !important;
          }
        }
      }
      .save {
        border-radius: 25px;
        width: auto;
        min-width: auto;
        background-color: #d72518;
        span {
          color: #fff;
        }
        &:hover {
          background-color: #fff;
          border: 1px solid #d72518;
          span {
            color: #d72518;
          }
        }
      }
    }
  }

  .modal-dialog {
    max-width: 726px !important;
    .main_modal_body {
      border-top: 1px solid #f5f5f5;
      border-bottom: 1px solid #f5f5f5;
      margin: 0 -30px;
      padding: 0 30px;
      padding-top: 16px;

      .s1 {
        font-size: 14px;
        font-weight: 400;
        color: #262626 !important;
        margin-bottom: 16px;
      }
    }
    .modal_bottom {
      justify-content: flex-end;
      button {
        &:first-child {
          margin-right: 16px;
        }
        width: auto !important;
        min-width: 168px;

        &.cancel_btn {
          background-color: #fff;
          border: 1px solid #51c2bc;
          span {
            color: #51c2bc;
          }
          &:hover {
            background-color: #51c2bc;
            span {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.ant-popover-inner {
  min-width: 500px;

  .ant-popover-title {
    font-family: "Poppins";
  }
  .leave_table_td {
    font-family: "Poppins";
    width: 100%;
    padding: 8px 8px;
    background-color: #d8f4f1;
    border-radius: 12px;
    margin-bottom: 10px;
    border-width: 1px !important;
    .ant-popover-inner {
      padding: 0 !important;
      box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.08),
        0 4px 8px -4px rgba(0, 0, 0, 0.12),
        0 11px 30px 8px rgba(0, 0, 0, 0.05) !important;
    }
    &.leave_table {
      width: auto;
    }
    &.monthview {
      width: 165px;
    }
    &.dayView {
      position: absolute;
      z-index: 1;
    }
    &.sick_leave {
      background-color: #f9dfde;
    }
    &.locum {
      background-color: #cfe5fc;
      border: 2px solid #3578bf;
    }
    &.pending_review {
      background-color: #fdeec7;
      border: 2px dashed #000;
    }
    .review {
      align-items: center;
      span {
        margin-left: auto;
        border-radius: 25px;
        background-color: #000;
        padding: 4px 10px;
        color: #fff;
      }
    }
    &.no_name {
      background-color: #fff;
      border: 2px dashed #9d9d9d;
    }
    h6 {
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      color: #101828;
    }
    p {
      margin-bottom: 0;
      font-size: 13px;
      font-weight: 500;
    }
  }
}
