@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700&display=swap");
//********************Assets
@import "./variables";
@import "./typography";

// //*********************Mixins
@import "./mixin";

.btn-style {
  height: 40px;
}

.ant-select-multiple .ant-select-selection-overflow {
  // overflow-x: auto;
  flex-wrap: nowrap;
}
.ant-select-multiple .ant-select-selection-overflow::-webkit-scrollbar {
  // display: none; /* Hide the scrollbar in WebKit browsers */
  height: 5px;
}

.ant-picker-dropdown .ant-picker-time-panel-column::after {
  height: auto;
}
:where(.css-dev-only-do-not-override-1h9hc1x).ant-radio-wrapper {
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: #09988d !important;
      background-color: #e1f4f4 !important;
      &::after {
        background-color: #09988d !important;
      }
    }
  }
}
:where(.css-dev-only-do-not-override-1h9hc1x).ant-radio-wrapper
  span.ant-radio
  + * {
  @extend .p2;
  color: #7b7b7b;
}

:where(.css-dev-only-do-not-override-1h9hc1x).ant-picker-dropdown
  .ant-picker-time-panel-column::after {
  display: none;
}

:where(.css-dev-only-do-not-override-1h9hc1x).ant-checkbox + span {
  @extend .b1;
  color: #7b7b7b;
}

:where(.css-dev-only-do-not-override-1h9hc1x).ant-btn > span {
  @extend .b1;
  color: #f5f5f5;
}

:where(.css-dev-only-do-not-override-gz0wk6).ant-btn-primary {
  @extend .b1;
  color: #ffffff;

  :disabled {
    color: #7b7b7b;
    cursor: not-allowed;
  }
}
button {
  :disabled {
    cursor: not-allowed !important;
  }
}
.ant-btn-default {
  @extend .b1;
  color: #7b7b7b;
  // background: #f5f5f5;
  border-radius: 8px;
  //border: none;
  --antd-wave-shadow-color: none !important;

  span {
    color: #7b7b7b;
  }

  :hover {
    color: #7b7b7b;
    border-color: #d9d9d9;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    border: none !important;
    text-decoration: none;
  }
}

.ant-btn-primary {
  @extend .b1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;

  :disabled {
    color: #7b7b7b;
  }
}

.ant-btn-default:not(:disabled):hover {
  color: #7b7b7b;
}

.ant-btn-default:not(:disabled):active {
  color: #7b7b7b;
}

:where(.css-dev-only-do-not-override-gz0wk6).ant-input {
  @extend .t1;
}

:where(.css-dev-only-do-not-override-1h9hc1x).ant-input-affix-wrapper
  > input.ant-input {
  @extend .t1;
}

:where(.css-dev-only-do-not-override-1h9hc1x).ant-select-dropdown
  .ant-select-item-option-content {
  @extend .t1;
}

.ant-form-item-label > label.ant-form-item-required::before {
  content: none;
}

:where(.css-dev-only-do-not-override-1h9hc1x).ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  @extend .t1;
  line-height: 30px;
}

.ant-form-item
  .ant-form-item-row
  .ant-form-item-label
  .ant-form-item-required::before {
  position: absolute;
  right: -10px;
  margin-inline-end: 0 !important;
}

.ant-input-wrapper {
  // border: 1px solid $input-border-color !important;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px;
  padding: 2px;
  height: 40px;
}

.searchField {
  .ant-input-wrapper {
    border-radius: 50px;
  }
  .ant-input-affix-wrapper {
    border-radius: 50px !important;
  }
  .ant-input {
    margin-left: 10px;
  }
}
.filter-wrapper {
  button {
    &.ant-btn {
      border-radius: 50px !important;
    }
  }

  .ant-picker {
    border-radius: 50px;
  }
  .ant-select {
    border-radius: 50px !important;
  }
}
.heading {
  button {
    &.ant-btn {
      border-radius: 50px !important;
    }
  }
}

.ant-input-affix-wrapper {
  // margin-left: 16px;
  // background: #ffffff !important;
  border: 1px solid $input-border-color;
  height: 40px;
  border-radius: 0.37rem !important;

  /* Colors/Black/Black 200 */
  &:focus {
    text-decoration: none;
    border: 1px solid $input-border-color;
  }

  &:hover {
    text-decoration: none;
    border: 1px solid $input-border-color !important;
  }

  &:active {
    text-decoration: none;
    border: 1px solid $input-border-color !important;
  }
}

.ant-input-affix-wrapper-hoverd {
  border-color: #d9d9d9 !important;
}

.ant-input-affix {
  display: none;
}

// .ant-input-search .ant-input-affix-wrapper-focused {
//   /* Shadow/xs */
//   background: #ffffff !important;
//   box-shadow: none !important;
//   border-radius: none;
// }

.ant-input-group-addon {
  display: none !important;
  box-shadow: none !important;

  :hover {
    color: #7b7b7b;
  }

  .ant-btn {
    background: #ffffff !important;
    border: none !important;
    box-shadow: none !important;

    :hover {
      color: #7b7b7b;
    }
  }
}

ul {
  list-style-type: disc !important;
}

p {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal !important;
}

span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-checkbox-wrapper {
  @extend .p2;
  color: #7b7b7b;
  margin-inline-start: 0px !important;
}

.ant-select-dropdown {
  min-width: 150px;
  width: auto;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: flex;
  align-items: center;
  background-color: transparent;
}

.ant-collapse-header-text {
  @extend .p2;
  line-height: 20px;
}

.faqContainer {
  .ant-collapse-item-active {
    margin-bottom: 20px;
    background: #f0f9f9;
    border-radius: 16px;
  }

  .ant-collapse-content-box {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-left: 32px !important;
  }

  .ant-collapse {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.Toastify__toast-theme--light {
  background-color: none;
  color: transparent;
}

.Toastify__toast-container {
  width: 500px !important;
  height: 40px;
}

.Toastify__toast {
  box-shadow: none;
}

.Toastify__toast--error {
  background: #fffcf5 !important;
  border: 1px solid #fec84b !important;
  color: #b54708 !important;
  border-radius: 8px !important;
}

.Toastify__toast--success {
  background: #f6fef9 !important;
  color: #027a48 !important;

  border: 1px solid #6ce9a6 !important;
  border-radius: 8px !important;
}

.Toastify__toast--info {
  background: #fcfcfd !important;
  /* Gray/300 */
  color: #344054 !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px;
}

.Toastify__close-button {
  margin-top: 12px;
}

// timepicker footer
.ant-picker-footer .ant-picker-ok {
  display: none !important;
}

.ant-picker-footer .ant-picker-now {
  width: 100%;
  text-align: center;

  .ant-picker-now-btn {
    text-decoration: none !important;
    color: $primary-500;
  }
}

// datepicker presets

.ant-picker-presets > ul > li {
  border: 1px solid $input-border-color !important;
  border-radius: 3px !important;

  &:hover {
    border: 1px solid $primary-300 !important;
    background-color: $primary-50 !important;
    color: $primary-400;
  }
}

// confirm modal

.modal {
  --bs-modal-width: 550px;
  z-index: 1050;
}
.btn {
}

.rc-virtual-list-holder {
  // height: 265px !important;
  // overflow: auto !important;
  .rc-virtual-list-holder-inner {
    transform: translateY(0) !important;
  }
  > div {
    max-height: inherit !important;
    overflow: auto !important;
  }
  > div {
    scrollbar-width: thin !important;
    scrollbar-color: transparent transparent !important;
  }
  > div::-webkit-scrollbar {
    width: 6px !important;
  }
  > div::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
}
.rc-virtual-list-holder {
  // overflow: auto !important;
  scrollbar-width: thin !important;
  scrollbar-color: transparent transparent !important;
}

.rc-virtual-list-holder::-webkit-scrollbar {
  width: 6px !important;
}

.rc-virtual-list-holder::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}

.shift_job_radio_group {
  .ant-radio-inner {
    width: 20px;
    height: 20px;
  }

  .ant-radio-group {
    &.shift_radio_group {
      .ant-radio-wrapper {
        span {
          font-family: "Poppins" !important;
        }
      }
    }
  }
  .ant-radio-checked {
    .ant-radio-inner {
      background-color: #e1f4f4 !important;
      border: 1px solid #09988d !important;
      &:after {
        background: #09988d !important;

        width: 20px;
        height: 20px;

        margin-block-start: -10px;
        margin-inline-start: -10px;
      }
    }
  }
}

.ant-checkbox-wrapper-checked {
  .ant-checkbox-inner {
    background-color: #e1f4f4;
    border-color: #09988d;
    &::after {
      border-color: #09988d;
    }
  }
}
.ant-form-item-control-input {
  .ant-form-item-control-input-content {
    button {
      &.ant-switch {
        background-color: #e2e8f0;
        &.ant-switch-checked {
          background-color: #51c2bc;
        }
      }
    }
  }
}
.ant-select-selector {
  &::after {
    display: none;
  }
}
